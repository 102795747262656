import styled from "styled-components";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { sitePurple } from "../../providers/Theme";
import { BODY_FONT_SIZE } from "../../pages/shared.styled.components";

export const StyledCard = styled(Card).attrs({ className: "mb-5" })`
  min-width: 300px;
  max-width: 350px;

  min-height: 400px;

  margin-left: auto;
  margin-right: auto;
`;

export const CardText = styled(Card.Text)`
  font-size: ${BODY_FONT_SIZE};
`;

export const PurpleButton = styled(Button)`
  background-color: ${sitePurple};
  border: 3px solid ${sitePurple};
  border-radius: 0;

  width: 75%;
  font-size: ${BODY_FONT_SIZE};
  font-weight: bold;
  margin: 0 auto;
  margin-top: auto;

  &:hover {
    color: ${sitePurple};
    background-color: white;
    border: 3px solid ${sitePurple};
  }

  &:active {
    color: ${sitePurple} !important;
    background-color: white !important;
    border: 3px solid ${sitePurple} !important;
  }

  &:focus {
    color: ${sitePurple};
    background-color: white;
    border: 3px solid ${sitePurple};
  }
`;

export const CardBody = styled(Card.Body)`
  display: flex;
  flex-direction: column;
`;
