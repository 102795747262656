import React from "react";
import { SubHeader, BlueLine } from "../../pages/shared.styled.components";
import { VideoCardDeck, StyledCard } from "./styled.components";
import Card from "react-bootstrap/Card";

interface Video {
  src: string;
  title: string;
}

interface VideoLibraryProps {
  videos: Video[];
}

const VideoLibrary: React.FC<VideoLibraryProps> = ({ videos }) => {
  return (
    <>
      <SubHeader>Video Library</SubHeader>
      <BlueLine />

      <VideoCardDeck>
        {videos.map((video, idx) => (
          <StyledCard key={`video-${video.title}-${idx}`.replace(" ", "")}>
            <iframe
              className="embed-responsive-item"
              src={video.src}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="video"
            />

            <Card.Title className="mt-2">{video.title}</Card.Title>
          </StyledCard>
        ))}
      </VideoCardDeck>
    </>
  );
};

export default VideoLibrary;
