import styled from "styled-components";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";

export const StyledFooter = styled.div`
  background: #292929;
  height: 200px;
  color: white;
  width: 100%;
`;

export const StyledImage = styled(Image)`
  height: 75px;
  margin: auto;
`;

export const StyledCol = styled(Col)`
  margin: auto;
`;

export const SocialMediaLink = styled.a.attrs({
  target: "_blank"
})`
  svg {
    color: white;
  }

  margin: 0.5rem;

  ${({ theme }) => theme.sm`
    margin: 0.25rem;
  `}

  ${({ theme }) => theme.md`
    margin: 0.5rem;
  `}

  ${({ theme }) => theme.lg`
    margin: 0.75rem;
  `}
`;
