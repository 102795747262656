import React from "react";
import KyahBanner from "../../assets/kyah-home.jpg";
import Col from "react-bootstrap/Col";
import {
  BannerTitle,
  BannerText,
  StyledRow,
  StyledImage,
  MissionCol,
  ImageBanner
} from "./styled.components";

const titleText = "Our Mission.";
const bodyText =
  "The Kyah Rayne Foundation is dedicated to advancing food allergy awareness and education and increasing Epinephrine Auto-Injector availability to save lives.";

const HomePageBanner: React.FC = () => {
  return (
    <>
      {/* Visible on large screens */}
      <StyledRow>
        <Col lg="4">
          <StyledImage fluid src={KyahBanner} />
        </Col>
        <MissionCol lg="8" xs="12">
          <BannerTitle>{titleText}</BannerTitle>
          <BannerText>{bodyText}</BannerText>
        </MissionCol>
      </StyledRow>

      {/* Visible on small screens */}
      <ImageBanner>
        <Col className="my-auto">
          <BannerTitle>{titleText}</BannerTitle>
          <BannerText>{bodyText}</BannerText>
        </Col>
      </ImageBanner>
    </>
  );
};

export default HomePageBanner;
