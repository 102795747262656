import React from "react";
import { sitePurple } from "../../providers/Theme";
import LawsPolicyLegislationCard from "../../components/LawsPolicyLegislationCard";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import { HeaderSubText, StyledRow, StyledCardDeck } from "./styled.components";
import { PageContainer, Header } from "../shared.styled.components";

const LawsPolicyLegislation: React.FC = () => {
  return (
    <>
      <Jumbotron fluid className="bg-white py-4">
        <Container>
          <Header color={sitePurple}>
            Understand the Laws / Policy / Legislation
          </Header>
          <HeaderSubText>
            The Kyah Rayne Foundation supports food allergy awareness and
            legislation. Learn about bills, acts, and laws that facilitate safer
            environments for individuals with food allergies and increase the
            availability of epinephrine.
          </HeaderSubText>
        </Container>
      </Jumbotron>

      <StyledRow>
        <PageContainer>
          <StyledCardDeck>
            <LawsPolicyLegislationCard
              title="Protecting Children with Food Allergies Act (S. 121)"
              link="https://www.congress.gov/bill/118th-congress/senate-bill/121?s=1&r=20"
              content="This bill will make our schools safer by requiring food allergen training for all food service personnel and providing food allergy and early introduction information to WIC personnel."
            />

            <LawsPolicyLegislationCard
              title="Adina Act: Labeling Medicine with the Top-Nine Allergens and Gluten"
              link="https://www.foodallergy.org/resources/2023-courage-congress-recap-and-resources"
              content="We support the right-to-know which allergens and gluten are in prescriptions. Medicines that should make us well- should not make us sick."
            />

            <LawsPolicyLegislationCard
              title="Food Allergy Safety, Treatment, Education and Research Act of 2021 (FASTER Act)"
              link="https://www.congress.gov/bill/117th-congress/senate-bill/578/text?q=%7B%22search%22%3A%5B%22S.+578%22%5D%7D&r=1&s=1"
              content="This bill expands the definition of major food allergen for purposes of certain food-labeling requirements to specifically include sesame. In addition, the Department of Health and Human Services must report on certain information related to food allergy research and data collection activities."
            />

            <LawsPolicyLegislationCard
              title="Good Samaritan Laws"
              link="https://www.ncbi.nlm.nih.gov/books/NBK542176/"
              content="Good Samaritan laws offer legal protection to people who give reasonable assistance to those who are, or whom they believe to be, injured, ill, in peril, or otherwise incapacitated. The protection is intended to reduce bystanders' hesitation to assist, for fear of being sued or prosecuted for unintentional injury or wrongful death."
            />
            <LawsPolicyLegislationCard
              title="House Bill 1473"
              link="https://mgaleg.maryland.gov/mgawebsite/legislation/details/hb1473?ys=2018rs"
              content="Maryland House Bill establishing the Emergency Use Auto Injectable Epinephrine Program for Food Service facilities at Institutions of Higher Education in Arizona."
            />
            <LawsPolicyLegislationCard
              title="Kelsey Ryan Act"
              link="https://www.flrules.org/gateway/ruleNo.asp?id=6A-6.0251"
              content="Kelsey Ryan Act gives students the right to carry and self-administer epinephrine on school grounds if exposed to their specific life-threatening allergens."
            />
            <LawsPolicyLegislationCard
              title="Sabrina’s Law"
              link="https://www.ola.org/en/legislative-business/bills/parliament-38/session-1/bill-3"
              content="The law, which took effect January 1, 2006, requires school boards to have all principals implement anaphylaxis plans that include: strategies to reduce exposure to allergens; procedures to communicate to parents, students and employees about life-threatening allergies; and regular training on dealing with life-threatening allergies for teachers and staff."
            />
            <LawsPolicyLegislationCard
              title="Bill 201 the EPI Bill"
              link="https://docs.assembly.ab.ca/LADDAR_files/docs/bills/bill/legislature_30/session_1/20190521_bill-201.pdf"
              content="Bill 201, the Protection of Students With Life-Threatening Allergies Act, would require a minimum of one epinephrine auto injector to be maintained in all publicly funded schools in Alberta, Canada."
            />
            <LawsPolicyLegislationCard
              title="Food Safety Modernization Act (FSMA)"
              link="https://www.fda.gov/food/food-safety-modernization-act-fsma/full-text-food-safety-modernization-act-fsma#SEC112"
              content="US Food and Drug Administration (FDA) policy surrounding the development and provision of plans for individuals to manage the risk of food allergy and anaphylaxis in schools and early childhood education programs."
            />
            <LawsPolicyLegislationCard
              title="Substitute House Bill No. 5452"
              link="https://custom.statenet.com/public/resources.cgi?id=ID:bill:CT2018000H5452&ciq=ncsl7&client_md=43271fb5bdbaff6dae6610f907c3414e&mode=current_text"
              content="An act in the State of Connecticut concerning the development of guidelines for the management of students with life-threatening food allergies in schools and the inclusion of life-threatening food allergies, dietary restrictions, cross-contamination and allergen identification in the Healthy and Balanced Living Curriculum Framework and culinary arts programs."
            />
          </StyledCardDeck>
        </PageContainer>
      </StyledRow>
    </>
  );
};

export default LawsPolicyLegislation;
