import styled from "styled-components";
import Container from "react-bootstrap/Container";
import { siteGray } from "../../providers/Theme";

export const StyledContainer = styled(Container)`
  background-color: ${siteGray};
  color: black;
`;

export const ImageContainer = styled.div.attrs({ className: "mx-auto" })`
  ${({ theme }) => theme.md`
    height: 75%;

    img {
      height: 100%;
    }
  `}
`;
