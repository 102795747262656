import styled from "styled-components";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { siteLightBlue } from "../../providers/Theme";

export const StyledCard = styled(Card)`
  border: none;

  width: 80%;
  ${({ theme }) => theme.md`
    width: 100%;
  `}

  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const CardImg = styled(Card.Img)`
  border-radius: 0;
`;

export const StyledButton = styled(Button)`
  font-size: 1.15rem;
  text-align: left;
  background-color: white;
  color: black;
  border: none;
  padding: 0;

  &:hover {
    background-color: white;
    color: black;
    border: none;
    text-decoration: underline;
  }

  &:active {
    background-color: white !important;
    color: black !important;
    border: none;
  }
`;

export const CardTitle = styled(Card.Title)`
  font-weight: bold;
  color: ${siteLightBlue};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
