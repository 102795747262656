import styled from "styled-components";
import { LightButton } from "../shared.styled.components";

export const ContactInfoContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  margin-bottom: 3rem;
  padding-top: 1.5rem;

  ${({ theme }) => theme.xs`
    width: 90%;
    margin-bottom: 3rem;
  `}

  ${({ theme }) => theme.sm`
    width: 80%;
  `}

  ${({ theme }) => theme.md`
    margin-bottom: 0;
    width: 100%;
  `}
`;

export const StyledLightButton = styled(LightButton)`
  width: 300px;
  font-size: 24px;
  height: 90px;

  margin-top: 1.5rem;
`;
