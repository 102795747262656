import React from "react";
import { useCustomRouter } from "../../hooks/useCustomRouter";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import {
  StyledCard,
  CardBodyContainer,
  StyledHeader,
  PurpleButton,
  ButtonContainer,
  ButtonText
} from "./styled.components";

interface BackgroundImgCardProps {
  src: string;
  title: string;
  path: string;
}

const BackgroundImgCard: React.FC<BackgroundImgCardProps> = ({
  src,
  title,
  path
}) => {
  const customRouter = useCustomRouter();

  const goToPath = () => {
    customRouter.append(path);
  };

  return (
    <Col className="col-auto my-2">
      <StyledCard src={src}>
        <CardBodyContainer>
          <Card.Body>
            <StyledHeader>{title}</StyledHeader>
          </Card.Body>

          <ButtonContainer>
            <PurpleButton onClick={goToPath}>
              <ButtonText>Read More</ButtonText>
            </PurpleButton>
          </ButtonContainer>
        </CardBodyContainer>
      </StyledCard>
    </Col>
  );
};

export default BackgroundImgCard;
