import styled from "styled-components";
import Col from "react-bootstrap/Col";

const minWidth = "210px";

export const ListHeader = styled.h6`
  font-weight: bold;
`;

export const StyledCol = styled(Col)`
  min-width: ${minWidth};
`;

export const NameItem = styled.div`
  min-width: ${minWidth};
`;
