import React from "react";
import { useCustomRouter } from "../../hooks/useCustomRouter";

export interface TextLinkInternalProps {
  text: string;
  path: string;
}

const TextLinkInternal: React.FC<TextLinkInternalProps> = ({ text, path }) => {
  const customRouter = useCustomRouter();

  const goToPath = (path: string) => {
    customRouter.append(path);
  };

  return <div onClick={() => goToPath(path)}>{text}</div>;
};

export default TextLinkInternal;
