import React from "react";
import {
  siteDarkBlue,
  siteLightBlue,
  sitePurple,
  siteDarkGreen,
  siteYellow
} from "./providers/Theme";
import Home from "./pages/Home";
import FoodAndAllergyBasics from "./pages/FoodAndAllergyBasics";
import Trainings from "./pages/Trainings";
import KyahsEpiCourseForSchools from "./pages/KyahsEpiCourseForSchools";
import KyahsEpiCourseForFoodService from "./pages/KyahsEpiCourseForFoodService";
import GetInvolved from "./pages/GetInvolved";
import LawsPolicyLegislation from "./pages/LawsPolicyLegislation";
import AdvocateForChange from "./pages/AdvocateForChange";
import VolunteersAndTrainers from "./pages/VolunteersAndTrainers";
import OutreachCallTeam from "./pages/OutreachCallTeam";
import Amendments from "./pages/Amendments";
import AboutUs from "./pages/AboutUs";
import FoodAllergy101 from "./pages/FoodAllergy101";
import TriggerFoods from "./pages/TriggerFoods";
import FoodAllergyRisks from "./pages/FoodAllergyRisks";
import Anaphylaxis from "./pages/Anaphylaxis";
import Epinephrine from "./pages/Epinephrine";
import EpinephrineFAQ from "./pages/EpinephrineFAQ";
import FactsAndFigures from "./pages/FactsAndFigures";
import Collaborators from "./pages/Collaborators";
import KyahsStory from "./pages/KyahsStory";
import StrategyTeam from "./pages/StrategyTeam";
import MedicalAdvisoryBoard from "./pages/MedicalAdvisoryBoard";
import ConnectWithUs from "./pages/ConnectWithUs";
import InternTeam from "./pages/InternTeam";
import InTheNews from "./pages/InTheNews";
import NewsletterArchive from "./pages/NewsletterArchive";
import Donate from "./pages/Donate";
import Thankyou from "./pages/Thankyou";

interface Route {
  title: string;
  path: string;
  key: string;
  component: React.FunctionComponent<any>;
}

interface NavConfig {
  displayAs: string;
  color: string;
}

interface DropdownNavConfig extends NavConfig {
  displayAs: "Dropdown";
  children: string[]; // array of keys for child routes
}

interface LinkNavConfig extends NavConfig {
  displayAs: "Link";
}

type NavItemConfig = DropdownNavConfig | LinkNavConfig;

interface PageItem extends Route {
  showOnNavBar?: false;
  navConfig?: undefined;
}

export interface NavItem extends Route {
  showOnNavBar: true;
  navConfig: NavItemConfig;
}

export type Page = PageItem | NavItem;

type PagesConfigObj = Record<string, Page>;

export const HomePage: PageItem = {
  title: "Home",
  path: "/",
  key: "home",
  component: Home
};

const PagesConfig: PagesConfigObj = {
  home: HomePage,
  "food-allergy-basics": {
    title: "Food Allergy Basics",
    path: "/food-allergy-basics",
    key: "food-allergy-basics",
    component: FoodAndAllergyBasics,
    showOnNavBar: true,
    navConfig: {
      displayAs: "Dropdown",
      color: siteDarkBlue,
      children: [
        "food-allergy-101",
        "facts-and-figures",
        "trigger-foods",
        "food-allergy-risks",
        "anaphylaxis",
        "epinephrine"
      ]
    }
  },
  "food-allergy-101": {
    title: "Food Allergy 101",
    path: "/food-allergy-basics/food-allergy-101",
    key: "food-allergy-101",
    component: FoodAllergy101
  },
  "trigger-foods": {
    title: "Trigger Foods",
    path: "/food-allergy-basics/trigger-foods",
    key: "trigger-foods",
    component: TriggerFoods
  },
  "food-allergy-risks": {
    title: "Food Allergy Risks",
    path: "/food-allergy-basics/food-allergy-risks",
    key: "food-allergy-risks",
    component: FoodAllergyRisks
  },
  anaphylaxis: {
    title: "Anaphylaxis",
    path: "/food-allergy-basics/anaphylaxis",
    key: "anaphylaxis",
    component: Anaphylaxis
  },
  epinephrine: {
    title: "Epinephrine",
    path: "/food-allergy-basics/epinephrine",
    key: "epinephrine",
    component: Epinephrine
  },
  "epinephrine-faq": {
    title: "Epinephrine FAQ",
    path: "/food-allergy-basics/epinephrine/epinephrine-faq",
    key: "epinephrine-faq",
    component: EpinephrineFAQ
  },
  "facts-and-figures": {
    title: "Facts and Figures",
    path: "/food-allergy-basics/facts-and-figures",
    key: "facts-and-figures",
    component: FactsAndFigures
  },
  trainings: {
    title: "Trainings",
    path: "/trainings",
    key: "trainings",
    component: Trainings,
    showOnNavBar: true,
    navConfig: {
      displayAs: "Dropdown",
      color: siteLightBlue,
      children: [
        "kyahs-epicourse-for-schools",
        "kyahs-epicourse-for-food-service"
      ]
    }
  },
  "kyahs-epicourse-for-schools": {
    title: "Kyah's EPICourse for Schools",
    path: "/trainings/kyahs-epicourse-for-schools",
    key: "kyahs-epicourse-for-schools",
    component: KyahsEpiCourseForSchools
  },
  "kyahs-epicourse-for-food-service": {
    title: "Kyah's EPICourse for Food Service",
    path: "/trainings/kyahs-epicourse-for-food-service",
    key: "kyahs-epicourse-for-food-service",
    component: KyahsEpiCourseForFoodService
  },
  "get-involved": {
    title: "Get Involved",
    path: "/get-involved",
    key: "get-involved",
    component: GetInvolved,
    showOnNavBar: true,
    navConfig: {
      displayAs: "Dropdown",
      color: sitePurple,
      children: [
        "laws-policy-legislation",
        "advocate-for-change",
        "volunteers-and-trainers"
      ]
    }
  },
  "laws-policy-legislation": {
    title: "Laws, Policy & Legislation",
    path: "/get-involved/laws-policy-legislation",
    key: "laws-policy-legislation",
    component: LawsPolicyLegislation
  },
  "advocate-for-change": {
    title: "Advocate for Change",
    path: "/get-involved/advocate-for-change",
    key: "advocate-for-change",
    component: AdvocateForChange
  },
  "volunteers-and-trainers": {
    title: "Volunteers and Trainers",
    path: "/get-involved/volunteers-and-trainers",
    key: "volunteers-and-trainers",
    component: VolunteersAndTrainers
  },
  "outreach-call-team": {
    title: "Outreach Call Team",
    path: "/get-involved/volunteers-and-trainers/outreach-call-team",
    key: "outreach-call-team",
    component: OutreachCallTeam
  },
  "about-us": {
    title: "About Us",
    path: "/about-us",
    key: "about-us",
    component: AboutUs,
    showOnNavBar: true,
    navConfig: {
      displayAs: "Dropdown",
      color: siteDarkGreen,
      children: [
        "kyahs-story",
        "in-the-news",
        "newsletter-archive",
        "collaborators",
        "connect-with-us"
      ]
    }
  },
  collaborators: {
    title: "Collaborators",
    path: "/about-us/collaborators",
    key: "collaborators",
    component: Collaborators
  },
  "kyahs-story": {
    title: "Kyah's Story",
    path: "/about-us/kyahs-story",
    key: "kyahs-story",
    component: KyahsStory
  },
  "in-the-news": {
    title: "In the News",
    path: "/about-us/in-the-news",
    key: "in-the-news",
    component: InTheNews
  },
  "newsletter-archive": {
    title: "Newsletter Archive",
    path: "/about-us/newsletter-archive",
    key: "newsletter-archive",
    component: NewsletterArchive
  },
  amendments: {
    title: "Amendments",
    path: "/about-us/in-the-news/amendments",
    key: "amendments",
    component: Amendments
  },
  "connect-with-us": {
    title: "Connect With Us",
    path: "/about-us/connect-with-us",
    key: "connect-with-us",
    component: ConnectWithUs
  },
  "strategy-team": {
    title: "Strategy Team",
    path: "/about-us/connect-with-us/strategy-team",
    key: "strategy-team",
    component: StrategyTeam
  },
  "medical-advisory-board": {
    title: "Advisory Board",
    path: "/about-us/connect-with-us/medical-advisory-board",
    key: "medical-advisory-board",
    component: MedicalAdvisoryBoard
  },
  "intern-team": {
    title: "Intern Team",
    path: "/about-us/connect-with-us/intern-team",
    key: "intern-team",
    component: InternTeam
  },
  thankyou: {
    title: "Thank you",
    path: "/thankyou",
    key: "thankyou",
    component: Thankyou
  },
  donate: {
    title: "Donate",
    path: "/donate",
    key: "donate",
    component: Donate,
    showOnNavBar: true,
    navConfig: {
      displayAs: "Link",
      color: siteYellow
    }
  }
};

export default PagesConfig;
