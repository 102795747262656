import React from "react";
import Card from "react-bootstrap/Card";
import { StyledCard, CardTitle } from "./styled.components";

export interface TakeActionCardProps {
  icon: string;
  actionElement: React.ReactElement;
}

const TakeActionCard: React.FC<TakeActionCardProps> = ({
  icon,
  actionElement
}) => {
  return (
    <StyledCard>
      <Card.Img variant="top" src={icon} />
      <Card.Body>
        <CardTitle>{actionElement}</CardTitle>
      </Card.Body>
    </StyledCard>
  );
};

export default TakeActionCard;
