import { useHistory, useLocation } from "react-router-dom";
import PagesConfig, { HomePage } from "../pages-config";

interface Breadcrumb {
  title: string;
  path: string;
  key: string;
}
interface CustomRouter {
  rootPath: string;
  breadCrumbs: Breadcrumb[];
  push: (path: string) => void;
  append: (path: string) => void;
}

interface RouterState {
  breadCrumbs: Breadcrumb[];
}

export function useCustomRouter(): CustomRouter {
  const history = useHistory();
  const location = useLocation();

  const { pathname } = location;
  const rootPath = `/${pathname.replace(/^\/([^/]*).*$/, "$1")}`;

  const getBreadCrumbs = (path: string): Breadcrumb[] => {
    const HomePageBreadcrumb = { ...HomePage, component: undefined };
    if (path === "/") return [HomePageBreadcrumb];

    const pathParts = path.split("/").slice(1);
    const breadCrumbs: Breadcrumb[] = pathParts.reduce(
      (acc: Breadcrumb[], pathPart: string) => {
        const { title, path, key } = PagesConfig[pathPart];

        return acc.concat({ title, path, key });
      },
      [HomePageBreadcrumb]
    );

    return breadCrumbs;
  };

  const push = (path: string) => {
    const breadCrumbs = getBreadCrumbs(path);

    history.push(path, { breadCrumbs });
    window.scrollTo(0, 0);
  };

  const append = (path: string) => {
    const fullPath = `${pathname}${path}`;
    push(fullPath);
  };

  const routerState = location.state as RouterState;
  const breadCrumbs = routerState?.breadCrumbs
    ? routerState.breadCrumbs
    : getBreadCrumbs(pathname);

  return { rootPath, breadCrumbs, push, append };
}
