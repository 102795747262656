import styled from "styled-components";
import Image from "react-bootstrap/Image";
import LisaSignature from "../../assets/lisa-signature.png";
import { BodyText } from "../shared.styled.components";
import { siteDarkBlue } from "../../providers/Theme";

interface BackgroundContainerProps {
  src: string;
}

export const BackgroundContainer = styled.div<BackgroundContainerProps>`
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center, center;

  height: 100%;
  width: 100%;

  min-height: 400px;
`;

export const Signature = styled(Image).attrs({ src: LisaSignature })`
  max-width: 150px;
`;

export const BodyTextNoMargin = styled(BodyText)`
  margin-bottom: 0 !important;
`;

export const TextBlue = styled.div`
  color: ${siteDarkBlue};
  font-weight: bold;
  display: inline;
`;
