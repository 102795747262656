import styled from "styled-components";
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";

export const StyledCard = styled(Card)`
  min-height: 200px;
  height: 100%;
  border: none;
  padding-top: 1rem;
  text-align: center;

  ${({ theme }) => theme.sm`
    min-width: 290px;
    max-width: 290px;
  `};

  ${({ theme }) => theme.xl`
    min-width: 320px;
    max-width: 320px;
  `};
`;

export const VideoCardDeck = styled(CardDeck)`
  justify-content: center;

  ${({ theme }) => theme.lg`
    justify-content: normal;
  `}
`;
