import React from "react";
import TriggerFoodsImg from "../../assets/trigger-foods.png";
import Image from "react-bootstrap/Image";
import { ImageContainer } from "./styled.components";
import {
  PageContainer,
  Header,
  BodyText,
  SubHeader
} from "../shared.styled.components";

const TriggerFoods: React.FC = () => {
  return (
    <PageContainer>
      <Header>Trigger Foods</Header>
      <BodyText>
        Trigger foods are particular foods or substances in a food that can
        cause a food allergy. There is a broad spectrum of allergens that can
        cause severe allergic reactions and anaphylaxis, not just food. These
        allergens range from insects, animals, plants, grasses, medicines, and
        many others.
      </BodyText>
      <BodyText>
        There are 8 food groups regulated by the U.S. Food & Drug Administration
        and they are:{" "}
        <b>milk, soy, wheat, peanuts, shellfish, eggs, fish, and tree nuts</b>.
        Beginning January 1, 2023, <b>sesame</b> will become the 9th major food
        allergen to be regulated by the FDA.
      </BodyText>

      <ImageContainer>
        <Image fluid src={TriggerFoodsImg} />
      </ImageContainer>

      <SubHeader>
        If you EAT a certain food, can an allergic reaction occur?
      </SubHeader>
      <BodyText>
        Absolutely! Eating a trigger food can cause a person to have an allergic
        reaction.
      </BodyText>

      <SubHeader>
        If you TOUCH a certain food, can an allergic reaction occur?
      </SubHeader>
      <BodyText>
        If a person touches an object that has not been properly cleaned it
        could contain trace amounts of a trigger food and cause a local
        reaction. The likelihood that a person develops a severe allergic
        reaction or anaphylaxis is low.
      </BodyText>

      <SubHeader>
        If you SMELL or BREATHE IN a certain food, can an allergic reaction
        occur?
      </SubHeader>
      <BodyText>
        Shellfish or other allergens can become airborne and cause symptoms to
        develop.**According to the American College of Allergy, Asthma &
        Immunology (2014), outside of cases related to cooking shellfish, the
        chances of having a severe reaction to airborne allergens are very low.
      </BodyText>
    </PageContainer>
  );
};

export default TriggerFoods;
