import React from "react";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import { StyledCol, DownLoadLink } from "./styled.components";
import {
  BodyText,
  SubHeader,
  BlueLine
} from "../../pages/shared.styled.components";

interface TrainingMaterial {
  text: string;
  subText: string;
  file: string;
  fileName: string;
  thumbnail: string;
}

interface TrainingMaterialsProps {
  header: string;
  trainingMaterials: TrainingMaterial[];
}

const TrainingMaterials: React.FC<TrainingMaterialsProps> = ({
  header,
  trainingMaterials
}) => {
  return (
    <>
      <SubHeader className="mt-5">{header}</SubHeader>
      <BlueLine />

      <Row>
        {trainingMaterials.map((trainingMaterial, idx) => (
          <StyledCol key={`training-material-${idx}`}>
            <Row>
              <StyledCol>
                <Image fluid src={trainingMaterial.thumbnail} />
              </StyledCol>
              <StyledCol>
                <BodyText>
                  <DownLoadLink
                    href={trainingMaterial.file}
                    download={trainingMaterial.fileName}
                  >
                    {trainingMaterial.text}
                  </DownLoadLink>{" "}
                  {trainingMaterial.subText}
                </BodyText>
              </StyledCol>
            </Row>
          </StyledCol>
        ))}
      </Row>
    </>
  );
};

export default TrainingMaterials;
