import React from "react";
import { ThemeProvider, css } from "styled-components";

export const siteDarkBlue = "#2e3c9b";
export const siteLightBlue = "#3098FF";
export const sitePurple = "#B740B3";
export const siteYellow = "#FFC715";
export const siteLightGreen = "#92D050";
export const siteDarkGreen = "#40B75E";
export const siteGray = "#e9ecef";

interface MediaQueryProps {
  [key: string]: number;
}

const sizes: MediaQueryProps = {
  xs: 376,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
};

const Theme = ({ children }: { children: React.ReactNode }) => {
  const media = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (literals: TemplateStringsArray, ...placeholders: any[]) =>
      css`
        @media (min-width: ${sizes[label]}px) {
          ${css(literals, ...placeholders)};
        }
      `.join("");
    return acc;
  }, {} as Record<keyof MediaQueryProps, (l: TemplateStringsArray, ...p: any[]) => string>);

  return <ThemeProvider theme={{ ...media }}>{children}</ThemeProvider>;
};
export default Theme;
