import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { StyledButton, StyledCol } from "./styled.components";
import { siteGray } from "../../providers/Theme";
import { StyledJumbotron } from "../../pages/shared.styled.components";
import { goToExternalUrl } from "../../utils";

export interface NewsletterProps {
  title: string;
  src: string;
  link: string;
}

const Newsletter: React.FC<NewsletterProps> = ({ title, src, link }) => {
  return (
    <StyledJumbotron backgroundColor={siteGray} color="black">
      <Row>
        <Col xs={12} lg={6}>
          <Container className="d-flex justify-content-center">
            <Image fluid src={src}></Image>
          </Container>
        </Col>

        <StyledCol className="d-flex justify-content-center align-items-center text-center">
          <Container>
            <StyledButton onClick={() => goToExternalUrl(link)}>
              <h3>{title}</h3>
            </StyledButton>
          </Container>
        </StyledCol>
      </Row>
    </StyledJumbotron>
  );
};

export default Newsletter;
