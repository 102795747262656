import React from "react";
import { StyledCard } from "./styled.components";
import Card from "react-bootstrap/Card";

interface InternTeamCardProps {
  imageSrc: string;
  name: string;
}

const InternTeamCard: React.FC<InternTeamCardProps> = ({ imageSrc, name }) => {
  return (
    <StyledCard>
      <Card.Img variant="top" src={imageSrc} />
      <Card.Body className="px-0">
        <Card.Title className="font-weight-bold">{name}</Card.Title>
      </Card.Body>
    </StyledCard>
  );
};

export default InternTeamCard;
