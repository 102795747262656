import React from "react";
import CrampingImg from "../../../src/assets/cramping.png";
import {
  PageContainer,
  Header,
  BodyText,
  ListContainer,
  ListItem,
  StyledJumbotron
} from "../shared.styled.components";
import { StyledContainer, ImageContainer } from "./styled.components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

const FoodAllergy101: React.FC = () => {
  return (
    <PageContainer>
      <Header>Food Allergy 101</Header>

      <BodyText>
        A food allergy happens when your immune system overreacts to what should
        be a harmless food protein – an allergen. Food allergies are potentially
        life-threatening and life-altering. Anyone who has a food allergy can
        have a severe allergic reaction to food.
      </BodyText>

      <StyledJumbotron backgroundColor="white">
        <StyledContainer className="p-4">
          <BodyText>
            <strong>WEBMD HEALTH NEWS</strong>
          </BodyText>

          <ListContainer>
            <ListItem>
              33 million Americans have life threatening food allergies
              including nearly 6 million children.
            </ListItem>
            <ListItem>
              Every 3 minutes a food allergy reaction sends someone to the
              emergency room.
            </ListItem>
            <ListItem>One in 10 adults have a food allergy.</ListItem>
            <ListItem>
              More than half (51.1%) have had a severe reaction.
            </ListItem>
            <ListItem>
              Only one in 20 with a convincing food allergy have a doctor
              confirmed diagnosis.
            </ListItem>
            <ListItem>
              Less than a quarter (24%) with a food allergy report a current
              epinephrine prescription.
            </ListItem>
            <ListItem>
              Food allergies add $25 billion in cost to the American economy.
              The average family spends over $4100 per food allergic child in
              extra costs each year.
            </ListItem>
          </ListContainer>
        </StyledContainer>
      </StyledJumbotron>

      <BodyText>
        About <strong>90 percent of allergic reactions</strong> come from these
        eight foods alone: Milk, eggs, peanuts, tree nuts, soy, wheat, fish and
        shellfish. In total, food allergies cause about 300,000 ambulatory-care
        visits a year, just among children under age 18. You can suddenly get
        food allergies as an adult. According to the American College of
        Allergy, Asthma, & Immunology,{" "}
        <strong>food allergy symptoms can appear at any age</strong> and impact
        up to 4 percent of adults. You can develop an allergy to foods you've
        eaten for years with no previous reaction.
      </BodyText>

      <Row>
        <Col xs={12} md={4} lg={3} className="my-auto">
          <BodyText>
            A food intolerance or a reaction to another substance you ate may
            cause the same signs and symptoms as a food allergy does — such as
            nausea, vomiting, cramping and diarrhea. By contrast, if you have a
            true food allergy, even a tiny amount of food may trigger an
            allergic reaction.
          </BodyText>
        </Col>
        <Col
          xs={12}
          md={8}
          lg={9}
          className="d-flex flex-wrap align-items-center"
        >
          <ImageContainer>
            <Image className="pb-4" fluid src={CrampingImg} />
          </ImageContainer>
        </Col>
      </Row>

      <BodyText>
        The rise in allergies and asthma may be due to an increase in airborne
        pollens, climate changes that trigger a rise in pollen levels, the
        energy-proofing of indoor home and work spaces, urban air pollution, or
        the overuse of antibiotics. Factors such as hygiene and lack of exposure
        to microbial factors, composition of the intestinal microbiota, diet,
        obesity, Vitamin D, and environmental chemical exposure have all been
        proposed to contribute to this alarming rise in the rate of food allergy
        in countries with a Westernized lifestyle
      </BodyText>

      <BodyText>
        U.S. Centers for Disease Control and Prevention shows that{" "}
        <strong>
          food allergies in children have increased approximately 50 percent
        </strong>{" "}
        between 1997 and 2011, now affecting 1 in 13 children in the United
        States. This translates to roughly{" "}
        <strong>two students in every classroom.</strong>
      </BodyText>
    </PageContainer>
  );
};

export default FoodAllergy101;
