import React from "react";
import PageTitleBanner from "../../components/PageTitleBanner";
import BackgroundImgCard from "../../components/BackgroundImgCard";
import GetInvolvedBannerImg from "../../assets/get-involved-banner.png";
import LaysAndPolicyImg from "../../assets/laws-policy.png";
import VolunteersAndTrainersImg from "../../assets/volunteers-and-trainers.png";
import AdvocateForChangeImag from "../../assets/advocate-for-change.png";
import SSMPStockEpiFlyer from "../../assets/SSMPStockEpiFlyer.pdf";
import CardDeck from "react-bootstrap/CardDeck";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { sitePurple } from "../../providers/Theme";
import {
  StyledContainer,
  CardsRow,
  StyledText,
  StyledLink
} from "./styled.components";
import {
  BodyText,
  StyledJumbotron,
  LightButton
} from "../shared.styled.components";

const GetInvolved: React.FC = () => {
  const goToExternalUrl = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <PageTitleBanner
        title="Get Involved"
        content="Learn how you can Step In, Step Up. The section contains information about current food allergy laws and policies. Learn how you can lend your voice to advocate for change in schools and in your state."
        imageSrc={GetInvolvedBannerImg}
      />

      <CardsRow className="py-5">
        <StyledContainer>
          <CardDeck className="d-flex flex-wrap justify-content-center">
            <BackgroundImgCard
              src={LaysAndPolicyImg}
              path="/laws-policy-legislation"
              title="Understand the Laws / Policy / Legislation"
            />
            <BackgroundImgCard
              src={VolunteersAndTrainersImg}
              path="/volunteers-and-trainers"
              title="Volunteers and Trainers"
            />
            <BackgroundImgCard
              src={AdvocateForChangeImag}
              path="/advocate-for-change"
              title="Advocate for Change"
            />
          </CardDeck>
        </StyledContainer>
      </CardsRow>

      <Row className="d-flex flex-wrap align-items-center p-4">
        <Col sm={3}>
          <Container className="text-center">
            <h2>EAI Discount Programs</h2>
          </Container>
        </Col>
        <Col sm={9}>
          <Container className="py-5">
            <StyledText>
              High costs often prevent those with food allergies from being able
              to carry their own epinephrine auto-injectors (EAIs).{" "}
              <StyledLink
                href="https://docs.google.com/spreadsheets/d/1aBu_7zl4YLxHk-AghzA3wczUHhLWj3n-MHt7nJaswxw/edit?usp=sharing"
                target="_blank"
                color={sitePurple}
              >
                Click here
              </StyledLink>{" "}
              for a list of programs and discounts designed to lower the costs
              of EAIs.
            </StyledText>
          </Container>
        </Col>
      </Row>

      <StyledJumbotron className="mb-0" backgroundColor={sitePurple}>
        <Container className="text-center">
          <h1>
            <b>Register Your School in the SSMP</b>
          </h1>

          <BodyText>
            The goal of the{" "}
            <StyledLink
              href="https://www.maricopa.gov/5038/Rescue-Medication-Surveillance"
              target="_blank"
              color="white"
            >
              School Surveillance and Medication Program (SSMP)
            </StyledLink>{" "}
            is to provide school systems with guidelines and implementation of
            policies and procedures that encourage schools to obtain a stock
            albuterol inhaler and epinephrine auto-injectors to potentially save
            lives of students with a severe unknown allergic reaction and/or
            respiratory distress.
          </BodyText>

          <BodyText>
            For more information about the program and how to enroll your
            school, click the link below.
          </BodyText>

          <LightButton
            onClick={() => goToExternalUrl(SSMPStockEpiFlyer)}
            color={sitePurple}
          >
            Learn More
          </LightButton>
        </Container>
      </StyledJumbotron>
    </>
  );
};

export default GetInvolved;
