import styled from "styled-components";
import { BodyText } from "../shared.styled.components";
import { siteDarkBlue, siteGray } from "../../providers/Theme";
import Tabs from "react-bootstrap/Tabs";
import Container from "react-bootstrap/Container";

export const ImageContainer = styled.div.attrs({ className: "mx-auto" })`
  width: 75%;
  ${({ theme }) => theme.lg`
      width: 100%;
  `}
`;

export const BlueItalicText = styled(BodyText)`
  color: ${siteDarkBlue};
  font-weight: bold;
  font-style: italic;
`;

export const NoMarginBodyText = styled(BodyText)`
  margin-bottom: 0 !important;
`;

export const FAQLink = styled.h1`
  color: ${siteDarkBlue};
  cursor: pointer;
  text-decoration: underline;
`;

export const StyledTabs = styled(Tabs)`
  background-color: white;

  .nav-link {
    color: #495057;
    font-size: 16px;
    padding: 16px;
    font-weight: 600;
    width: 100%;

    text-align: center;

    ${({ theme }) => theme.lg`
      width: 200px;
   `}

    ${({ theme }) => theme.md`
      font-size: 20px;
   `}

    ${({ theme }) => theme.sm`
      width: 25%;
   `}

    background-color: ${siteGray};

    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      color: ${siteDarkBlue};
      border-left: none;
    }

    border-right: 2px solid lightgray;
    border-bottom: 2px solid lightgray;
    border-radius: 0;

    &:last-of-type {
      border-right: none;
    }
  }
`;

export const TabBody = styled(Container).attrs({ className: "pt-3 px-5" })`
  background-color: white;
`;

export const TabsContainer = styled(Container)`
  max-width: 810px;
  margin: 0;

  border-top: 3px solid ${siteGray};
  border-bottom: 5px solid ${siteGray};
  border-left: 5px solid ${siteGray};
  border-right: 5px solid ${siteGray};
`;

export const VideoContainer = styled.div.attrs({
  className: "embed-responsive embed-responsive-16by9 mx-auto"
})`
  top: 0;
  left: 0;
  clip-path: inset(2px 2px);
  ${({ theme }) => theme.sm`
      width: 75%;
   `}
`;
