import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import {
  CardText,
  CardTitle,
  StyledImage,
  StyledCol
} from "./srtyled.components";

export interface TeamMemberCardProps {
  src: string;
  title: string;
  content: string;
  subTitle?: string;
}

const TeamMemberCard: React.FC<TeamMemberCardProps> = ({
  src,
  title,
  subTitle,
  content
}) => {
  return (
    <Row className="pt-4">
      <Card className="mx-auto mb-5 border-0">
        <Row className="d-flex flex-wrap align-items-center">
          <StyledCol md={4} xl={3}>
            <StyledImage fluid src={src} />
          </StyledCol>

          <Col md={8} xl={9}>
            <Container>
              <CardTitle>{title}</CardTitle>
              {subTitle && (
                <CardText className="mb-2 font-italic">{subTitle}</CardText>
              )}
              <CardText>{content}</CardText>
            </Container>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default TeamMemberCard;
