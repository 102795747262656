import styled from "styled-components";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { siteDarkGreen } from "../../providers/Theme";

export const StyledCard = styled(Card)`
  border: none;

  margin-top: 1rem;
  width: 80%;

  ${({ theme }) => theme.md`
    width: 100%;
    max-width: 450px;
  `}

  ${({ theme }) => theme.xl`
    width: 100%;
    max-width: 250px;
  `}

  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const CardImg = styled(Card.Img)`
  border-radius: 0;
  object-fit: cover;
  flex-grow: 1;
`;

export const StyledButton = styled(Button)`
  font-size: 1.15rem;
  font-weight: bold;
  text-align: left;
  background-color: white;
  color: ${siteDarkGreen};
  border: none;
  padding: 0;

  &:hover {
    background-color: white;
    color: ${siteDarkGreen};
    border: none;
    text-decoration: underline;
  }

  &:active {
    background-color: white !important;
    color: ${siteDarkGreen} !important;
    border: none;
  }
`;

export const CardTitle = styled(Card.Title)`
  font-weight: bold;
`;
