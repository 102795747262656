import React from "react";
import { BodyText } from "../../pages/shared.styled.components";
import { StyledButton, StyledCol } from "./styled.components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DonateForm from "../../components/DonateForm";

interface DonationLevelProps {
  title: string;
  amount: string;
  body: React.ReactElement;
  color: string;
}

const DonationLevel: React.FC<DonationLevelProps> = ({
  title,
  amount,
  body,
  color
}) => {
  return (
    <Row className="mb-5">
      <Col md={4}>
        <div className="text-center">
          <DonateForm
            triggerElement={
              <StyledButton color={color} size="lg">
                {title} <p className="mb-0">{`$${amount}`}</p>
              </StyledButton>
            }
          />
        </div>
      </Col>
      <StyledCol md={8} className="my-auto">
        <BodyText>{body}</BodyText>
      </StyledCol>
    </Row>
  );
};

export default DonationLevel;
