import { AppWrapper, Header } from "./App.styled";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import PagesConfig from "./pages-config";
import Footer from "./components/Footer";

export const App = () => {
  return (
    <AppWrapper>
      <Header>
        <NavigationBar />
      </Header>

      <Switch>
        {Object.values(PagesConfig).map(config => (
          <Route
            key={config.path}
            exact
            path={config.path}
            render={props => <config.component {...props} />}
          />
        ))}
      </Switch>

      <Footer />
    </AppWrapper>
  );
};

const AppContainer = () => (
  <Router>
    <App />
  </Router>
);

export default AppContainer;
