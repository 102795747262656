import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { siteGray } from "../../providers/Theme";
import { BODY_FONT_SIZE } from "../shared.styled.components";

export const StyledContainer = styled(Container)`
  background-color: ${siteGray};
`;

export const CardsRow = styled(Row)`
  background-color: ${siteGray};
`;

export const StyledText = styled.p`
  font-size: ${BODY_FONT_SIZE};
  margin-bottom: 0;
`;

interface StyledSubHeaderProps {
  color: string;
}

export const StyledLink = styled.a<StyledSubHeaderProps>`
  font-size: ${BODY_FONT_SIZE};
  font-weight: 800;
  color: ${props => props.color};
  text-decoration: underline;

  &:hover {
    color: ${props => props.color};
  }
`;
