import React from "react";
import { useCustomRouter } from "../../hooks/useCustomRouter";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  StyledCard,
  CardTitle,
  CardText,
  StyledButton,
  DeskTopCol,
  MobileCol,
  ThumbnailImage
} from "./styled.components";

export interface ThumbnailCardProps {
  src: string;
  title: string;
  path: string;
  content: string;
  reverse?: boolean;
}

const ThumbnailCard: React.FC<ThumbnailCardProps> = ({
  src,
  title,
  path,
  content,
  reverse
}) => {
  const customRouter = useCustomRouter();

  const goToPath = (path: string) => {
    customRouter.append(path);
  };

  return (
    <StyledCard className="mx-auto mb-5">
      <Card.Body>
        <Row className="d-flex flex-wrap align-items-center">
          {/* Always show image first on mobile */}
          <MobileCol md={5}>
            <ThumbnailImage src={src} />
          </MobileCol>

          {!reverse && (
            <DeskTopCol md={5}>
              <ThumbnailImage src={src} />
            </DeskTopCol>
          )}

          <Col md={7}>
            <CardTitle>{title}</CardTitle>
            <CardText>{content}</CardText>
            <StyledButton onClick={() => goToPath(path)}>
              Learn More
            </StyledButton>
          </Col>

          {reverse && (
            <DeskTopCol md={5}>
              <ThumbnailImage src={src} />
            </DeskTopCol>
          )}
        </Row>
      </Card.Body>
    </StyledCard>
  );
};

export default ThumbnailCard;
