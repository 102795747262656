import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import { ListHeader, StyledCol, NameItem } from "./styled.components";

interface InternNameListProps {
  listTitle: string;
  numColumns: number;
  numNamesPerCol: number;
  names: string[];
}

const InternNameList: React.FC<InternNameListProps> = ({
  listTitle,
  numColumns,
  numNamesPerCol,
  names
}) => {
  const [chunkedNames, setChunkedNames] = useState<string[][]>([[]]);
  useEffect(() => {
    const chunked = names.reduce<string[][]>((chunked, name, index) => {
      const chunkIndex = Math.floor(index / numNamesPerCol);
      if (!chunked[chunkIndex]) {
        chunked[chunkIndex] = []; // start a new chunk
      }
      chunked[chunkIndex].push(name);
      return chunked;
    }, []);

    setChunkedNames(chunked);
  }, [names, numNamesPerCol]);

  return (
    <>
      <ListHeader>{listTitle}</ListHeader>
      <Row>
        {[...Array(numColumns)].map((_, idx) => (
          <StyledCol xs={6} sm={3}>
            {chunkedNames[idx]?.map(name => (
              <NameItem>{name}</NameItem>
            ))}
          </StyledCol>
        ))}
      </Row>
    </>
  );
};

export default InternNameList;
