import React from "react";
import { BodyText } from "../../pages/shared.styled.components";
import { Address as AddressComponent } from "./styled.components";

const Address: React.FC = () => {
  return (
    <AddressComponent className="pt-2">
      <BodyText>Kyah Rayne Foundation</BodyText>
      <BodyText>3104 E. Camelback Road, Ste. 1172</BodyText>
      <BodyText>Phoenix, Arizona 85016</BodyText>
    </AddressComponent>
  );
};

export default Address;
