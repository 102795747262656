import styled from "styled-components";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { sitePurple } from "../../providers/Theme";

interface StyledCardProps {
  src: string;
}

export const StyledCard = styled(Card)<StyledCardProps>`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center, center;

  min-height: 400px;

  min-width: 300px !important;

  width: 80%;
  ${({ theme }) => theme.md`
    width: 100%;
  `}

  margin-left: auto;
  margin-right: auto;
`;

export const CardBodyContainer = styled.div`
  background-color: white;
  height: 70%;
  width: 80%;
  position: absolute;
  bottom: 0;
  margin-left: -1px;
  margin-bottom: -1px;
`;

export const StyledHeader = styled.h3`
  font-weight: 700;
`;

export const PurpleButton = styled(Button)`
  background-color: ${sitePurple};
  position: absolute;
  margin-bottom: 20px;
  bottom: 0;
  width: 75%;
  border: 3px solid ${sitePurple};
  border-radius: 0;

  &:hover {
    color: ${sitePurple};
    background-color: white;
    border: 3px solid ${sitePurple};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonText = styled.h4`
  font-weight: 500;
  margin-bottom: 0;
`;
