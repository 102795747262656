import React from "react";
import HomePageBanner from "../../components/HomePageBanner";
import TakeActionCard from "../../components/TakeActionCard";
import TextLinkInternal from "../../components/TextLinkInternal";
import EpiFirstEpiFastPoster from "../../assets/epi-first-epi-fast-poster.pdf";
import HolographicBg from "../../assets/KRF-holographic-bg.jpg";
import Container from "react-bootstrap/Container";
import DonateIcon from "../../assets/donate-icon.png";
import GetTrained from "../../assets/get-trained.png";
import JoinOurTeam from "../../assets/join-our-team.png";
import LearnAboutFa from "../../assets/learn-about-FA.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardDeck from "react-bootstrap/CardDeck";
import { siteGray, siteDarkBlue } from "../../providers/Theme";
import {
  ImageBanner,
  FlyerLink,
  VideoContainer,
  StyledContainer
} from "./styled.components";
import {
  BodyText,
  StyledJumbotron,
  ListContainer,
  ListItem,
  Header
} from "../shared.styled.components";

const Home: React.FC = () => {
  return (
    <>
      <HomePageBanner />

      <ImageBanner src={HolographicBg}>
        <Container fluid="md">
          <Row className="justify-content-center my-1">
            <Col>
              <VideoContainer>
                <iframe
                  className="embed-responsive-item"
                  src="https://player.vimeo.com/video/747017119?h=d6e9e16e8b"
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title="video"
                />
              </VideoContainer>
            </Col>
          </Row>
        </Container>
      </ImageBanner>

      <StyledJumbotron backgroundColor="white">
        <StyledContainer className="text-center p-5">
          <h2>NOBODY ELSE NEEDS TO DIE</h2>

          <BodyText>
            Food allergy awareness saves lives. Awareness and education are key
            to our mission. We are implementing training programs that promote
            and empower students, teachers and families to recognize the signs
            and symptoms of food allergies and anaphylaxis. The Kyah Rayne
            Foundation is working hard to increase the availability of
            Epinephrine Auto-Injectors to save lives. Our goal is to decrease
            and eliminate deaths due to food allergies with a long term goal of
            NO food allergy fatalities.
          </BodyText>

          <BodyText>
            Check out our{}
            <FlyerLink
              className="mx-1"
              href={EpiFirstEpiFastPoster}
              target="_blank"
            >
              flyer
            </FlyerLink>
            and SHARE this information NOW to help us save lives.
          </BodyText>
        </StyledContainer>
      </StyledJumbotron>

      <StyledJumbotron backgroundColor={siteDarkBlue}>
        <Container>
          <h2 className="pb-4 pl-3">Food Allergy Statistics</h2>

          <ListContainer>
            <ListItem>32 million Americans live with food allergies.</ListItem>
            <ListItem>
              Every 3 minutes, a severe allergic reaction to food sends someone
              to the emergency room.
            </ListItem>
            <ListItem>
              There is no cure for allergies. You can manage allergies with
              prevention and treatment. It is among the country’s most common,
              but overlooked, diseases.
            </ListItem>
            <ListItem>
              Epinephrine Auto-Injectors (ex. EpiPen®) must be administered
              within minutes of anaphylaxis and are the ONLY available
              treatment.
            </ListItem>
            <ListItem>
              One in 1 in 13 children has food allergies—that’s about 2 in every
              U.S. classroom.
            </ListItem>
            <ListItem>
              377% increase in diagnosis of anaphylactic food reactions between
              2007 and 2016.
            </ListItem>
          </ListContainer>
        </Container>
      </StyledJumbotron>

      <Container>
        <div className="text-center">
          <Header color="black">TAKE ACTION</Header>
        </div>

        <Row>
          <Container>
            <CardDeck className="d-flex flex-wrap justify-content-center">
              <TakeActionCard
                icon={LearnAboutFa}
                actionElement={
                  <TextLinkInternal
                    text="Learn more about food allergies >>"
                    path="food-allergy-basics"
                  />
                }
              />

              <TakeActionCard
                icon={GetTrained}
                actionElement={
                  <TextLinkInternal
                    text="Take our food allergy training >>"
                    path="trainings"
                  />
                }
              />

              <TakeActionCard
                icon={JoinOurTeam}
                actionElement={
                  <TextLinkInternal
                    text="Join our team >>"
                    path="get-involved/volunteers-and-trainers"
                  />
                }
              />

              <TakeActionCard
                icon={DonateIcon}
                actionElement={
                  <TextLinkInternal
                    text="Donate to our organization >>"
                    path="donate"
                  />
                }
              />
            </CardDeck>
          </Container>
        </Row>
      </Container>

      <StyledJumbotron
        className="mb-0"
        backgroundColor={siteGray}
        color="black"
      >
        <Container className="text-center">
          <h4 className="text-center pb-3">Disclaimer</h4>
          <p>
            The information provided here is for educational purposes only and
            is not intended to replace the medical advice of a doctor or
            healthcare provider. This information is provided by the Kyah Rayne
            Foundation, a qualified 501(c)(3) tax-exempt organization. By
            accessing this website and the resources located on this website,
            the visitor acknowledges that there is no physician-patient
            relationship between them and the authors. If you think you are
            observing a medical emergency such as but not limited to an allergic
            reaction, call 911 immediately.
          </p>
        </Container>
      </StyledJumbotron>
    </>
  );
};

export default Home;
