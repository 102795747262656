import styled from "styled-components";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { siteDarkBlue } from "../../providers/Theme";
import { BODY_FONT_SIZE } from "../../pages/shared.styled.components";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

export const StyledCard = styled(Card)`
  width: 80%;
  ${({ theme }) => theme.md`
      width: 100%;
  `}
`;

export const CardTitle = styled(Card.Title).attrs({ as: "h1" })`
  color: ${siteDarkBlue}!important;
  font-weight: 900;

  font-size: 2.25rem !important;
  ${({ theme }) => theme.xs`
     font-size: 2.5rem !important;
   `}
`;

export const CardText = styled(Card.Text)`
  font-size: ${BODY_FONT_SIZE};
`;

export const StyledButton = styled(Button).attrs({ className: "mt-auto" })`
  background-color: ${siteDarkBlue}!important;
  font-weight: bold;
`;

export const DeskTopCol = styled(Col)`
  display: none;
  ${({ theme }) => theme.md`
     display: block;
   `}
`;

export const MobileCol = styled(Col)`
  display: block;
  ${({ theme }) => theme.md`
     display: none;
   `}
`;

export const ThumbnailImage = styled(Image).attrs({
  fluid: true,
  thumbnail: true
})`
  border: none;
`;
