import React from "react";
import PageTitleBanner from "../../components/PageTitleBanner";
import HolographicBg from "../../assets/KRF-holographic-bg.jpg";
import Fall2023Newsletter from "../../assets/newsletter-items/fall-2023-newsletter.jpg";
import Spring2023Newsletter from "../../assets/newsletter-items/spring-2023-newsletter.jpg";
import September2022Newsletter from "../../assets/newsletter-items/september-2022-newsletter.jpg";
import March2022Newsletter from "../../assets/newsletter-items/march-2022-newsletter.jpg";
import September2021Newsletter from "../../assets/newsletter-items/september-2021-newsletter.png";
import Spring2024Newsletter from "../../assets/newsletter-items/spring-2024-newsletter.png";
import { siteDarkGreen, siteGray } from "../../providers/Theme";
import Container from "react-bootstrap/Container";
import { StyledButton } from "./styled.components";
import {
  SubHeader,
  StyledJumbotron,
  BodyText
} from "../shared.styled.components";
import NewsletterCategory from "../../components/NewsletterCategory";
import { goToExternalUrl } from "../../utils";

const NewsletterArchive: React.FC = () => {
  return (
    <>
      <PageTitleBanner title="Newsletter Archive" imageSrc={HolographicBg} />

      <Container fluid className="my-4 ml-3">
        <SubHeader color={siteDarkGreen}>Subscribe to Our Newsletter</SubHeader>
      </Container>

      <StyledJumbotron
        backgroundColor={siteGray}
        color="black"
        className="pb-0 mb-2"
      >
        <Container fluid className="text-center pb-4">
          <BodyText>
            Our newsletters are free and offer periodic updates on the Kyah
            Rayne Foundation's efforts to prevent anaphylaxis-related deaths and
            other developments regarding food allergy awareness.
          </BodyText>

          <BodyText>Click the button below to subscribe today!</BodyText>

          <StyledButton
            onClick={() => {
              goToExternalUrl(
                "https://kyahraynefoundation.us1.list-manage.com/subscribe?u=69716092fda5b8ecfebe0494e&id=ff12db3551"
              );
            }}
          >
            <h3>Subscribe</h3>
          </StyledButton>
        </Container>
      </StyledJumbotron>

      <NewsletterCategory
        title="Latest Newsletter"
        subText="Read our latest edition of the Kyah Rayne Foundation newsletter."
        newsletters={[
          {
            title: "Spring 2024 Newsletter",
            src: Spring2024Newsletter,
            link: "https://mailchi.mp/1186f8e51d45/kyah-rayne-foundation-spring-2024-newsletter"
          }
        ]}
      />

      <NewsletterCategory
        title="Previous Newsletters"
        subText="Read the archives of our newsletters."
        newsletters={[
          {
            title: "Fall 2023 Newsletter",
            src: Fall2023Newsletter,
            link: "https://mailchi.mp/63a5f0da8f5b/kyahraynefoundation-fallnewsletter2023"
          },
          {
            title: "Spring 2023 Newsletter",
            src: Spring2023Newsletter,
            link: "https://mailchi.mp/f5bcec756f8f/kyah-rayne-foundation-spring-newsletter-2023?e=[UNIQID"
          },
          {
            title: "September 2022 Newsletter",
            src: September2022Newsletter,
            link: "https://mailchi.mp/aa67e6ec7ea2/march-newsletter-9000306"
          },
          {
            title: "March 2022 Newsletter",
            src: March2022Newsletter,
            link: "https://mailchi.mp/9bfedf864702/march-newsletter"
          },
          {
            title: "September 2021 Newsletter",
            src: September2021Newsletter,
            link: "https://mailchi.mp/27898db3e3b1/august-newsletter-test-4971282?e=[UNIQID"
          }
        ]}
      />
    </>
  );
};

export default NewsletterArchive;
