import styled from "styled-components";
import { siteGray } from "../../providers/Theme";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { BODY_FONT_SIZE } from "../shared.styled.components";

export const StyledContainer = styled(Container).attrs({
  className: "d-flex flex-wrap mb-5"
})`
  background-color: ${siteGray};

  padding: 1.5rem;

  ${({ theme }) => theme.md`
    max-width: 80%;
    padding: 3rem;
  `}
`;

export const BorderCol = styled(Col)`
  border-bottom: none;
  padding-bottom: 25px;

  ${({ theme }) => theme.xs`
    border-right: none;
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 1.5rem;
  `}

  ${({ theme }) => theme.sm`
    border-right: 4px solid white;
  `}
`;

export const ColumnContainer = styled(Container)`
  width: 75%;
  font-weight: 300;
`;

export const JumbotronLink = styled.a`
  font-size: ${BODY_FONT_SIZE};
  color: white;
  text-decoration: underline;

  &:hover {
    color: white;
  }
`;
