import React from "react";
import PageTitleBanner from "../../components/PageTitleBanner";
import VideoLibrary from "../../components/VideoLibrary";
import TrainingMaterials from "../../components/TrainingMaterials";
import TrainingToolkits from "../../components/TrainingToolkits";
import JumbotronSplit from "../../components/JumbotronSplit";
import KyahsepicCourseForFoodServiceBanner from "../../assets/kyahs-epicourse-for-food-service.png";
import EpiFirstEpiFastPoster from "../../assets/epi-first-epi-fast-poster.pdf";
import EpiFirstEpiFastPosterThumbnail from "../../assets/epi-first-epi-fast-poster-thumbnail.png";
import FareEmergencyActionPlan from "../../assets/fare-emergency-care-plan.pdf";
import FareEmergencyActionPlanThumbnail from "../../assets/fare-emergency-care-plan-thumbnail.png";
import KRFPostcard from "../../assets/KRF-postcard.pdf";
import KRFPostcardThumbnail from "../../assets/KRF-postcard-thumbnail.png";
import FoodAllergyCertifiedSticker from "../../assets/food-allergy-certified-sticker.png";
import StockEpiFoodServiceToolkit from "../../assets/toolkits/stock-epinephrine-food-service-toolkit.pdf";
import StockEpiFoodServiceToolkitThumbnail from "../../assets/toolkits/stock-epinephrine-food-service-toolkit-thumbnail.png";
import SpanishMoodleGuideToolkit from "../../assets/toolkits/kyahs-epi-course-for-food-service-spanish-enrollment-instructions.pdf";
import SpanishMoodleGuideToolkitThumbnail from "../../assets/toolkits/kyahs-epi-course-for-food-service-spanish-enrollment-instructions-thumbnail.png";
import { siteLightBlue } from "../../providers/Theme";
import { PageContainer } from "../shared.styled.components";

const KyahsEpiCourseForFoodService: React.FC = () => {
  return (
    <>
      <PageTitleBanner
        title="Kyah's EPICourse for Food Service"
        content="Learn more about anaphylaxis, its causes, and the proper emergency response using our learning resources. This section contains videos on different topics in food allergies and downloadable graphics to help promote and guide food allergy response."
        imageSrc={KyahsepicCourseForFoodServiceBanner}
      />

      <JumbotronSplit
        title="Register for Kyah's EPICourse for Food Service"
        description={
          <>
            Take our free, online training course{" "}
            <strong>(now also offered in Spanish!)</strong> to learn about food
            allergies anaphylaxis, and how to administer epinephrine. Created in
            collaboration with the University of Arizona College of Public
            Health and the Western Region Public Health Training Center
            (WRPHTC), this training prepares individuals to quickly and safely
            respond to food allergy emergencies.
          </>
        }
        section1={{
          title: "Kyah's EPICourse for Food Service",
          link: "https://www.kyahsepicourse.com/course/view.php?id=3",
          buttonText: "REGISTER NOW"
        }}
        section2={{
          title: "Kyah's EPICourse para el Servicio de Alimentos",
          link: "https://www.kyahsepicourse.com/course/view.php?id=4",
          buttonText: "REGISTER NOW"
        }}
        bgColor={siteLightBlue}
      />

      <PageContainer>
        <VideoLibrary
          videos={[
            {
              src: "https://player.vimeo.com/video/743773430?h=fb8ff489a5?maxwidth=100",
              title: "Recognizing Signs and Symptoms"
            },
            {
              src: "https://player.vimeo.com/video/743773239?h=adc5abdb5c",
              title: "How to Administer an EAI"
            },
            {
              src: "https://player.vimeo.com/video/743773068?h=c8548b949b",
              title: "Creating Safe Environments"
            },
            {
              src: "https://player.vimeo.com/video/743774476?h=ce5accec70",
              title: "Avoiding Cross-Contact"
            },
            {
              src: "https://www.youtube-nocookie.com/embed/RZ6z1WfrFas?controls=0",
              title: "Steps To Administer An EAI"
            },
            {
              src: "https://www.youtube-nocookie.com/embed/wzmDb2gseWk?controls=0",
              title: "EAI Infographic"
            },
            {
              src: "https://www.youtube-nocookie.com/embed/nQG78NELzx0?controls=0",
              title: "Steps To Administer An EAI (Spanish)"
            },
            {
              src: "https://www.youtube-nocookie.com/embed/LV7RRxZolDQ?controls=0",
              title: "EAI Infographic (Spanish)"
            }
          ]}
        />

        <TrainingMaterials
          header="Props"
          trainingMaterials={[
            {
              text: "Epi FIRST, Epi FAST, ReACT.",
              subText:
                "Use this poster to help you and others remember how to respond to anaphylaxis.",
              file: EpiFirstEpiFastPoster,
              fileName: "epi-first-epi-fast.pdf",
              thumbnail: EpiFirstEpiFastPosterThumbnail
            },
            {
              text: "Download a Food Allergy Action Plan (FAAP) poster.",
              subText:
                "Post it in your office, cafe, restaurant, home, school classroom. Be a proponent of fast and efficient food allergy awareness and care.",
              file: FareEmergencyActionPlan,
              fileName: "food-allergy-action-plan.pdf",
              thumbnail: FareEmergencyActionPlanThumbnail
            },
            {
              text: "KRF Postcard.",
              subText: "Save lives, reduce death, and grow your business.",
              file: KRFPostcard,
              fileName: "KRF-postcard.pdf",
              thumbnail: KRFPostcardThumbnail
            },
            {
              text: "Food Allergy Certified Sticker.",
              subText:
                "Show that your business is prepared for food allergy emergencies.",
              file: FoodAllergyCertifiedSticker,
              fileName: "food-allergy-certified-sticker.png",
              thumbnail: FoodAllergyCertifiedSticker
            }
          ]}
        />

        <TrainingToolkits
          header="Tools"
          trainingToolkits={[
            {
              text: "Food Service Establishment Toolkit",
              file: StockEpiFoodServiceToolkit,
              thumbnail: StockEpiFoodServiceToolkitThumbnail
            },
            {
              text: "Inscribirse en Kyah's EPICourse para el Servicio de Alimentos",
              file: SpanishMoodleGuideToolkit,
              thumbnail: SpanishMoodleGuideToolkitThumbnail
            }
          ]}
        />
      </PageContainer>
    </>
  );
};

export default KyahsEpiCourseForFoodService;
