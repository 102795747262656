import React from "react";
import { IconStack } from "./styled.components";
import { siteDarkGreen } from "../../providers/Theme";
import { FaCircle } from "react-icons/fa";
import { IconType } from "react-icons";

interface SocialMediaIconProps {
  FaIcon: IconType;
  link: string;
}

const IconSize = "3rem";

const SocialMediaIcon: React.FC<SocialMediaIconProps> = ({ FaIcon, link }) => {
  const goToExternalUrl = () => {
    window.open(link, "_blank");
  };

  return (
    <IconStack className="mr-3" onClick={goToExternalUrl}>
      <FaCircle color={siteDarkGreen} size={IconSize} />
      <FaIcon className="p-2" color="white" size={IconSize} />
    </IconStack>
  );
};

export default SocialMediaIcon;
