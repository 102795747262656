import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { siteDarkGreen } from "../../providers/Theme";
import Col from "react-bootstrap/Col";

const buttonStates = `
    background-color: ${siteDarkGreen} !important;
    border: none !important;
    text-decoration: underline !important;
    outline: 0 !important;
    box-shadow: none;
`;

export const StyledButton = styled(Button)`
  background-color: ${siteDarkGreen} !important;
  border-color: ${siteDarkGreen} !important;
  border-radius: 1000px;
  padding: 0.625rem 1.875rem;
  width: 96%;
  font-size: 1.15rem;
  font-weight: bold;

  h3 {
    margin-bottom: 0px;
  }

  &:hover {
    ${buttonStates}
  }

  &:active {
    ${buttonStates}
  }
`;

export const StyledCol = styled(Col)`
  margin-top: 50px !important;

  ${({ theme }) => theme.lg`
    margin-top: 0 !important;
  `}
`;
