import React from "react";
import { useCustomRouter } from "../../hooks/useCustomRouter";
import {
  StyledCard,
  CardImg,
  StyledButton,
  CardTitle
} from "./styled.components";

interface AboutUsCardProps {
  src: string;
  title: string;
  path: string;
}

const AboutUsCard: React.FC<AboutUsCardProps> = ({ src, title, path }) => {
  const customRouter = useCustomRouter();

  const goToPath = (path: string) => {
    customRouter.append(path);
  };

  return (
    <StyledCard className="mx-auto">
      <CardImg variant="top" src={src} />

      <CardTitle className="mt-2 mb-0">{title}</CardTitle>

      <StyledButton onClick={() => goToPath(path)}>
        Learn More &gt;
      </StyledButton>
    </StyledCard>
  );
};

export default AboutUsCard;
