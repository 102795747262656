import React from "react";
import {
  BodyText,
  StyledJumbotron,
  LightButton,
  JumbotronHeader,
  JumbotronSubHeader
} from "../../pages/shared.styled.components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { StyledCol, BorderCol } from "./styled.components";

interface JumbotronSplitSectionProps {
  title: string;
  link: string;
  buttonText: string;
}

interface JumbotronSplitProps {
  title: string;
  description: React.ReactElement | string;
  bgColor: string;
  section1: JumbotronSplitSectionProps;
  section2: JumbotronSplitSectionProps;
}

const JumbotronSplit: React.FC<JumbotronSplitProps> = ({
  title,
  description,
  section1,
  section2,
  bgColor
}) => {
  const goTo = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <StyledJumbotron backgroundColor={bgColor}>
      <Container className="text-center">
        <JumbotronHeader>{title}</JumbotronHeader>

        <BodyText>{description}</BodyText>

        <Row>
          <BorderCol className="d-flex flex-column">
            <JumbotronSubHeader className="mb-3">
              {section1.title}
            </JumbotronSubHeader>

            <LightButton
              className="align-self-center mt-auto"
              onClick={() => goTo(section1.link)}
              color={bgColor}
            >
              {section1.buttonText}
            </LightButton>
          </BorderCol>
          <StyledCol className="d-flex flex-column">
            <JumbotronSubHeader className="mb-3">
              {section2.title}
            </JumbotronSubHeader>
            <LightButton
              className="align-self-center mt-auto"
              onClick={() => goTo(section2.link)}
              color={bgColor}
            >
              {section2.buttonText}
            </LightButton>
          </StyledCol>
        </Row>
      </Container>
    </StyledJumbotron>
  );
};

export default JumbotronSplit;
