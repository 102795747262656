import React from "react";
import { siteDarkGreen } from "../../providers/Theme";
import Container from "react-bootstrap/Container";
import {
  Header,
  BodyText,
  ListItem,
  StyledJumbotron,
  PageContainer,
  LightButton
} from "../shared.styled.components";

const Amendments: React.FC = () => {
  const learnMoreLink = () => {
    window.open("https://youtu.be/XC7jay1mpms?t=2122", "_blank");
  };

  return (
    <>
      <PageContainer>
        <Header color={siteDarkGreen}>
          Amendments to Rule-making Procedures on A.A.C. R7-2-809 and
          A.A.C.R7-2-810
        </Header>

        <BodyText>
          On August 27, 2021, KRF's Founder, Lisa Cohen presented a public
          comment at an Arizona State Board of Education Board Meeting regarding
          amendments to the rule-making procedures of epinephrine auto-injector
          (EAI) administration.
        </BodyText>

        <BodyText>
          KRF proposed three changes that would bring the rules into alignment
          with the law (A.R.S. 15-157) and remove barriers that limit online
          training for schools on handling emergency anaphylaxis with
          easy-to-use and safe rescue medication, such as EAIs.
        </BodyText>

        <ol>
          <ListItem>
            Update the requirements to include any two school personnel for SSMP
            training
          </ListItem>
          <ListItem>
            Adjust the language so that schools may stock juvenile and adult
            doses of auto-injectable epinephrine
          </ListItem>
          <ListItem>
            Clarify that any online, state-approved training course, including
            Kyah's EPICourse, meets the SSMP training standards
          </ListItem>
        </ol>
      </PageContainer>

      <StyledJumbotron className="mb-0" backgroundColor={siteDarkGreen}>
        <Container className="text-center">
          <h2 className="font-weight-bold">
            All three amendments have been approved!
          </h2>

          <BodyText>
            To watch Lisa's public comments (35:24 - 37:03), click the button
            below.
          </BodyText>

          <LightButton onClick={learnMoreLink} color={siteDarkGreen}>
            Learn More
          </LightButton>
        </Container>
      </StyledJumbotron>
    </>
  );
};

export default Amendments;
