import ThankyouBg from "../../assets/thankyou-bg.png";
import {
  BackgroundContainer,
  Signature,
  BodyTextNoMargin,
  TextBlue
} from "./styled.components";
import {
  Header,
  BodyText,
  ListContainer,
  ListItem
} from "../shared.styled.components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const Thankyou: React.FC = () => {
  return (
    <BackgroundContainer src={ThankyouBg}>
      <Container>
        <Row>
          <Col xs={0} sm={6}></Col>
          <Col xs={12} sm={6}>
            <Header>THANK YOU!</Header>

            <BodyText>YOUR CONTRIBUTION MAKES IT POSSIBLE FOR US TO:</BodyText>

            <ListContainer>
              <ListItem>
                Increase school enrollment rates in School Surveillance and
                Medication Program (SSMP)
              </ListItem>
              <ListItem>
                Sponsor internships with the University of Arizona Mel & Enid
                Zuckerman College of Public Health
              </ListItem>
              <ListItem>
                Revamp and modernize food allergy training with Kyah’s
                EPICourses: <TextBlue>Kyah’s EPICourse for Schools</TextBlue>{" "}
                and <TextBlue>Kyah’s EPICourse for Food Handlers</TextBlue>
              </ListItem>
              <ListItem>
                Advance our food allergy education outreach to schools,
                restaurants, sports facilities, camps and other public entities.
              </ListItem>
              <ListItem>
                Reduce the number of deaths from food allergies in Arizona
              </ListItem>
              <ListItem>
                Increase access to food allergy training to EAIs nationwide
              </ListItem>
            </ListContainer>

            <BodyText>
              Once again, thank you for making a difference with your generous
              donation!
            </BodyText>

            <BodyTextNoMargin>Sincerely,</BodyTextNoMargin>

            <Signature />

            <BodyText>Lisa Jan Cohen</BodyText>

            <BodyText>Founder & CEO, Kyah Rayne Foundation</BodyText>
          </Col>
        </Row>
      </Container>
    </BackgroundContainer>
  );
};

export default Thankyou;
