import styled from "styled-components";
import Col from "react-bootstrap/Col";

export const StyledCol = styled(Col).attrs({ sm: 6 })``;

export const BorderCol = styled(StyledCol)`
  border-bottom: none;

  ${({ theme }) => theme.xs`
    border-right: none;
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 1.5rem;
  `}

  ${({ theme }) => theme.sm`
    border-right: 4px solid white;
    margin-bottom: 0;
  `}
`;
