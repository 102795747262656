import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { siteDarkBlue } from "../../providers/Theme";
import KyahBanner from "../../assets/kyah-banner-cropped.jpg";

export const StyledRow = styled(Row)`
  background-color: ${siteDarkBlue};
  height: 400px;

  display: none;

  ${({ theme }) => theme.lg`
    display: flex;
  `}

  ${({ theme }) => theme.xxl`
      height: 500px;
  `}

  overflow: hidden;
`;

export const MissionCol = styled(Col)`
  padding: 8%;

  ${({ theme }) => theme.md`
    padding: 6rem 3rem 6rem 5rem;
  `}

  ${({ theme }) => theme.xxl`
      margin: auto;
  `}
`;

export const StyledImage = styled(Image)`
  border-top-right-radius: 60%100%;
  border-bottom-right-radius: 60%100%;

  position: absolute;
  top: -5%;
  bottom: 0;

  height: 110%;
  width: auto;
  min-width: 390px;
  object-fit: cover;

  display: none;

  ${({ theme }) => theme.lg`
    display: block;
  `}
`;

export const BannerTitle = styled.h1`
  color: white;
  font-weight: 900;
  font-size: 3rem;
`;

export const BannerText = styled.p`
  color: white;
  font-weight: 500;
  font-size: 28px;
`;

export const ImageBanner = styled(Row)`
  background-image: url(${KyahBanner});
  background-repeat: no-repeat;
  background-size: cover;

  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.05);

  height: 400px;
  padding: 2rem 1rem;
  overflow: hidden;

  ${({ theme }) => theme.sm`
    padding: 4rem 2rem;
  `}

  display: flex;

  ${({ theme }) => theme.lg`
    display: none;
  `}
`;
