import React from "react";
import PageTitleBanner from "../../components/PageTitleBanner";
import TrainingCard from "../../components/TrainingCard";
import TrainingsBanner from "../../assets/trainings-banner.png";
import KyahsEpiCourseForFoodService from "../../assets/kyahs-epicourse-for-food-service.jpg";
import KyahsEpiCourseForSchools from "../../assets/kyahs-epicourse-for-schools.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import CardDeck from "react-bootstrap/CardDeck";
import { siteLightBlue } from "../../providers/Theme";
import {
  PageContainer,
  BodyText,
  StyledJumbotron,
  JumbotronHeader
} from "../shared.styled.components";

const Trainings: React.FC = () => {
  return (
    <>
      <PageTitleBanner
        title="Trainings"
        content="Learn more about anaphylaxis, its causes, and the proper emergency response using our educational resources. This section contains videos on different topics in food allergies and downloadable graphics to help promote and guide food allergy response."
        imageSrc={TrainingsBanner}
      />

      <StyledJumbotron backgroundColor={siteLightBlue}>
        <Container className="text-center">
          <JumbotronHeader>Register for Kyah's EPICourse</JumbotronHeader>

          <BodyText>
            Take our free, online training courses to learn about food allergies
            anaphylaxis, and how to administer epinephrine. Created in
            collaboration with the University of Arizona College of Public
            Health and the Western Region Public Health Training Center
            (WRPHTC), these training courses prepare individuals to quickly and
            safely respond to food allergy emergencies.
          </BodyText>
        </Container>
      </StyledJumbotron>

      <PageContainer>
        <Row className="mb-4">
          <Container>
            <CardDeck>
              <TrainingCard
                src={KyahsEpiCourseForSchools}
                title="Kyah's EPICourse for Schools"
                path="/kyahs-epicourse-for-schools"
              />
              <TrainingCard
                src={KyahsEpiCourseForFoodService}
                title="Kyah's EPICourse for Food Service (English & Spanish)"
                path="/kyahs-epicourse-for-food-service"
              />
            </CardDeck>
          </Container>
        </Row>
      </PageContainer>
    </>
  );
};

export default Trainings;
