import React from "react";
import Card from "react-bootstrap/Card";
import {
  StyledCard,
  CardBody,
  CardText,
  PurpleButton
} from "./styled.components";

interface LawsPolicyLegislationCardProps {
  title: string;
  content: string;
  link: string;
}

const LawsPolicyLegislationCard: React.FC<LawsPolicyLegislationCardProps> = ({
  title,
  content,
  link
}) => {
  const goToExternalUrl = () => {
    window.open(link, "_blank");
  };

  return (
    <StyledCard className="mb-2">
      <CardBody>
        <Card.Title as="h3" className="font-weight-bold">
          {title}
        </Card.Title>

        <CardText>{content}</CardText>

        <PurpleButton onClick={goToExternalUrl}>Learn More</PurpleButton>
      </CardBody>
    </StyledCard>
  );
};

export default LawsPolicyLegislationCard;
