import styled from "styled-components";
import Row from "react-bootstrap/Row";
import CardDeck from "react-bootstrap/CardDeck";
import { siteGray } from "../../providers/Theme";

export const HeaderSubText = styled.p`
  font-size: 22px;
`;

export const StyledRow = styled(Row).attrs({ className: "pt-5" })`
  background-color: ${siteGray};
`;

export const StyledCardDeck = styled(CardDeck).attrs({
  className: "d-flex flex-wrap"
})`
  justify-content: center;
  ${({ theme }) => theme.xl`
    justify-content: normal;
  `}
`;
