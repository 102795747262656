import React from "react";
import PageTitleBanner from "../../components/PageTitleBanner";
import VideoLibrary from "../../components/VideoLibrary";
import TrainingMaterials from "../../components/TrainingMaterials";
import TrainingToolkits from "../../components/TrainingToolkits";
import JumbotronSplit from "../../components/JumbotronSplit";
import KyahsEpiCourseForSchoolsBanner from "../../assets/kyah-epicourse-for-schools.png";
import EpiFirstEpiFastPoster from "../../assets/epi-first-epi-fast-poster.pdf";
import EpiFirstEpiFastPosterThumbnail from "../../assets/epi-first-epi-fast-poster-thumbnail.png";
import FareEmergencyActionPlanThumbnail from "../../assets/fare-emergency-care-plan-thumbnail.png";
import FareEmergencyActionPlan from "../../assets/fare-emergency-care-plan.pdf";
import NationalCourseToolkitThumbnail from "../../assets/toolkits/national-kyahs-epi-course-for-schools-toolkit-thumbnail.png";
import NationalCourseToolkit from "../../assets/toolkits/national-kyahs-epi-course-for-schools-toolkit.pdf";
import SSMPMaricopaCountyToolkitThumbnail from "../../assets/toolkits/ssmp-maricopa-county-toolkit-thumbnail.png";
import SSMPMaricopaCountyToolkit from "../../assets/toolkits/ssmp-maricopa-county-toolkit.pdf";
import SSMPPimaCountyToolkitThumbnail from "../../assets/toolkits/ssmp-pima-county-toolkit-thumbnail.png";
import SSMPPimaCountyToolkit from "../../assets/toolkits/ssmp-pima-county-toolkit.pdf";
import { siteLightBlue } from "../../providers/Theme";
import { PageContainer } from "../shared.styled.components";

const KyahsEpiCourseForSchools: React.FC = () => {
  return (
    <>
      <PageTitleBanner
        title="Kyah's EPICourse for Schools"
        content="Learn more about anaphylaxis, its causes, and the proper emergency response using our learning resources. This section contains videos on different topics in food allergies and downloadable graphics to help promote and guide food allergy response."
        imageSrc={KyahsEpiCourseForSchoolsBanner}
      />

      <JumbotronSplit
        title="Register for Kyah's EPICourse"
        description="Take our free, online training courses to learn about food allergies anaphylaxis, and how to administer epinephrine. Created in collaboration with the University of Arizona College of Public Health and the Western Region Public Health Training Center (WRPHTC), these training courses prepare individuals to quickly and safely respond to food allergy emergencies."
        section1={{
          title: "National Kyah's EPICourse for Schools",
          link: "https://www.kyahsepicourse.com/course/view.php?id=5",
          buttonText: "REGISTER NOW"
        }}
        section2={{
          title: "Kyah's EPICourse for Arizona Schools",
          link: "https://moodle.publichealth.arizona.edu/course/view.php?id=532",
          buttonText: "REGISTER NOW"
        }}
        bgColor={siteLightBlue}
      />

      <PageContainer>
        <VideoLibrary
          videos={[
            {
              src: "https://player.vimeo.com/video/969100868?h=1e9260e284",
              title: "Kyah's EPICourse for Arizona Schools Enrollment Tutorial"
            },
            {
              src: "https://player.vimeo.com/video/743773430?h=fb8ff489a5?maxwidth=100",
              title: "Recognizing Signs and Symptoms"
            },
            {
              src: "https://player.vimeo.com/video/743773239?h=adc5abdb5c",
              title: "How to Administer an EAI"
            },
            {
              src: "https://player.vimeo.com/video/743773068?h=c8548b949b",
              title: "Creating Safe Environments"
            },
            {
              src: "https://www.youtube-nocookie.com/embed/RZ6z1WfrFas?controls=0",
              title: "Steps To Administer An EAI"
            },
            {
              src: "https://www.youtube-nocookie.com/embed/wzmDb2gseWk?controls=0",
              title: "EAI Infographic"
            },
            {
              src: "https://www.youtube-nocookie.com/embed/nQG78NELzx0?controls=0",
              title: "Steps To Administer An EAI (Spanish)"
            },
            {
              src: "https://www.youtube-nocookie.com/embed/LV7RRxZolDQ?controls=0",
              title: "EAI Infographic (Spanish)"
            }
          ]}
        />

        <TrainingMaterials
          header="Posters"
          trainingMaterials={[
            {
              text: "Epi FIRST, Epi FAST, ReACT.",
              subText:
                "Use this poster to help you and others remember how to respond to anaphylaxis.",
              file: EpiFirstEpiFastPoster,
              fileName: "epi-first-epi-fast.pdf",
              thumbnail: EpiFirstEpiFastPosterThumbnail
            },
            {
              text: "Download a Food Allergy Action Plan FAAP) poster.",
              subText:
                "Post it in your office, cafe, restaurant, home, school classroom. Be a proponent of fast and efficient food allergy awareness and care.",
              file: FareEmergencyActionPlan,
              fileName: "food-allergy-action-plan.pdf",
              thumbnail: FareEmergencyActionPlanThumbnail
            }
          ]}
        />

        <TrainingToolkits
          header="Stock Epinephrine Toolkits"
          trainingToolkits={[
            {
              text: "National Kyah’s EPICourse for Schools Toolkit",
              file: NationalCourseToolkit,
              thumbnail: NationalCourseToolkitThumbnail
            },
            {
              text: "SSMP Maricopa County Toolkit",
              file: SSMPMaricopaCountyToolkit,
              thumbnail: SSMPMaricopaCountyToolkitThumbnail
            },
            {
              text: "SSMP Pima County Toolkit",
              file: SSMPPimaCountyToolkit,
              thumbnail: SSMPPimaCountyToolkitThumbnail
            }
          ]}
        />
      </PageContainer>
    </>
  );
};

export default KyahsEpiCourseForSchools;
