import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { siteGray } from "../../providers/Theme";

export const StyledContainer = styled(Container)`
  background-color: ${siteGray};
  color: black;
`;

export const PayPalButton = styled.button`
  max-width: 500px;

  // Below are styles copied from donate page

  &:hover {
    background: rgb(255, 232, 136);
    border-color: rgb(255, 232, 136);
  }

  display: flex !important;
  align-items: center;
  justify-content: center;

  position: relative;
  border-radius: 1000px;
  cursor: pointer;
  display: inline-block;
  min-width: 6rem;
  text-align: center;
  text-decoration: none;
  transition: color 0.2s ease 0s, background-color 0.2s ease 0s,
    border-color 0.2s ease 0s, box-shadow 0.2s ease 0s;
  border: 0.125rem solid rgb(255, 209, 64);
  font-family: PayPalOpen-Bold, "Helvetica Neue", Arial, sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 400;
  padding: 0.625rem 1.875rem;
  background: rgb(255, 209, 64);
  color: rgb(0, 48, 135);

  width: 100%;
  white-space: normal !important;
`;

export const PayPalImage = styled(Image).attrs({
  src: "https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-mark-color.svg"
})`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;
