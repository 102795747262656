import React from "react";
import { siteDarkGreen } from "../../providers/Theme";
import InternTeamCard from "../../components/InternTeamCard";
import InternNameList from "../../components/InternNameList";
import { PageContainer, Header, SubHeader } from "../shared.styled.components";
import ErinBroas from "../../assets/intern-team/erin-broas.jpg";
import SamanthaMinichetti from "../../assets/intern-team/samantha-minichetti.jpg";
import EmiliaWilburn from "../../assets/intern-team/emilia-wilburn.jpg";
import CardDeck from "react-bootstrap/CardDeck";
import { ItalicHeader } from "./styled.components";

const InternTeam: React.FC = () => {
  return (
    <PageContainer>
      <Header color={siteDarkGreen}>Intern Team</Header>

      <SubHeader>Public Health Coordinators</SubHeader>

      <ItalicHeader>Past & Present</ItalicHeader>

      <CardDeck className="d-flex flex-wrap justify-content-center">
        <InternTeamCard
          imageSrc={SamanthaMinichetti}
          name="Samantha Minichetti"
        />

        <InternTeamCard imageSrc={ErinBroas} name="Erin Broas" />

        <InternTeamCard imageSrc={EmiliaWilburn} name="Emilia Wilburn" />
      </CardDeck>

      <SubHeader>
        Interns from the University of Arizona Mel & Enid Zuckerman College of
        Public Health
      </SubHeader>

      <ItalicHeader>
        Thank you for your dedication and work for the Kyah Rayne Foundation!
      </ItalicHeader>

      <br />

      <InternNameList
        listTitle="Undergraduate Public Health Students"
        numColumns={4}
        numNamesPerCol={6}
        names={[
          "Erin Broas",
          "Hana Fankhouser",
          "Madison Kim",
          "Madison Potthoff",
          "Ashlie Thorp",
          "Jailene Morales",
          "Anthony Luis",
          "Suhita	Muvvala",
          "Riley Holmes",
          "Jocelyn Giron",
          "Lexi Lenzkes",
          "Linh Luu",
          "Zoe Korban",
          "Tatum Liska",
          "Tristan Pachorek",
          "Kennedy Huston",
          "Taylor Henson",
          "Angel Nunez",
          "Anusha Basak",
          "Adilene Soto",
          "Madison Jones",
          "Ashlyn Nissim"
        ]}
      />

      <br />

      <InternNameList
        listTitle="Master's of Public Health Students"
        numColumns={4}
        numNamesPerCol={5}
        names={[
          "Emilia Steigert",
          "Jennifer Herbert",
          "Tori Coria",
          "Calli Stewart",
          "Gaye Gwion",
          "Samantha Minichetti",
          "Garrett Tomlin",
          "Sue Kim",
          "Vanessa Madrid-Resendiz",
          "Stefani House",
          "Tabark Abdelhabib",
          "Foram Patel"
        ]}
      />
    </PageContainer>
  );
};

export default InternTeam;
