import React from "react";
import { sitePurple } from "../../providers/Theme";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  StyledContainer,
  BorderCol,
  ColumnContainer,
  JumbotronLink
} from "./styled.components";
import {
  TopPageContainer,
  Header,
  SubHeader,
  BodyText,
  ListContainer,
  ListItem,
  StyledJumbotron,
  JumbotronHeader,
  JumbotronSubHeader
} from "../shared.styled.components";

const AdvocateForChange: React.FC = () => {
  return (
    <>
      <TopPageContainer>
        <Header color={sitePurple}>Advocate for Change</Header>

        <BodyText>
          The Kyah Rayne Foundation supports families living with food allergies
          and works to educate all communities about the disease. Through
          increased awareness and educational training surrounding food
          allergies, fundraising efforts, and policy change, our mission is to
          improve the quality of life for those affected by food allergies and
          to prevent deaths due to anaphylaxis.
        </BodyText>

        <BodyText>
          We are promoting a new House Bill that provides an Emergency Use Auto
          Injectable Epinephrine Program for Food Service facilities at
          Institutions of Higher Education in Arizona. Maryland House Bill 1473
          was passed in 2018. We hope to be the second state in the nation to
          follow with this new lifesaving Auto Injectable Epinephrine protocol.
        </BodyText>

        <StyledContainer>
          <SubHeader className="mx-auto text-center pb-3">
            You can be an advocate, too.
          </SubHeader>

          <BodyText>
            Your time and talent can make a difference in the lives of people
            with food allergies. Advocates can help bring awareness to the
            dangers of food allergies in their local community and help build
            support for legislation that facilitate safer environments.
          </BodyText>

          <ListContainer className="pb-2">
            <ListItem>
              Advocates conduct outreach to parents and families, schools,
              influencers, and policy makers
            </ListItem>
            <ListItem>
              They encourage their schools to enroll in the School Surveillance
              and Medication Program
            </ListItem>
            <ListItem>
              They remove the stigma surrounding food allergies by talking about
              them often
            </ListItem>
            <ListItem>
              They are aware of those around them and are ready to assist with
              epinephrine injection when they spot the signs of a severe
              allergic reaction
            </ListItem>
            <ListItem>
              They promote legislation that supports emergency allergy treatment
              programs
            </ListItem>
          </ListContainer>

          <BodyText>
            Deaths from food allergies are preventable, and you can be a part of
            making the world a safer place for everyone.
          </BodyText>
        </StyledContainer>
      </TopPageContainer>

      <StyledJumbotron backgroundColor={sitePurple} className="mb-0">
        <Row className="w-100">
          <JumbotronHeader>Take Action</JumbotronHeader>
        </Row>
        <Row className="w-100">
          <BorderCol sm={6} xs={12}>
            <ColumnContainer>
              <JumbotronSubHeader>
                Register for Kyah's EPICourse
              </JumbotronSubHeader>
              <BodyText>
                Take our free, online training course to learn about food
                allergies anaphylaxis, and how to administer epinephrine.
              </BodyText>
              <JumbotronLink
                href="https://moodle.publichealth.arizona.edu/enrol/index.php?id=495"
                target="_blank"
              >
                Register Now &gt;
              </JumbotronLink>
            </ColumnContainer>
          </BorderCol>
          <Col sm={6} xs={12}>
            <ColumnContainer>
              <JumbotronSubHeader>
                Make Sure Your Voice is Heard
              </JumbotronSubHeader>
              <BodyText>
                Tell Congress that epinephrine saves lives, and that it must be
                affordable and accessible to everyone who needs it.
              </BodyText>
              <JumbotronLink
                href="https://www.foodallergy.org/resources/why-we-need-faster-act"
                target="_blank"
              >
                Register Now &gt;
              </JumbotronLink>
            </ColumnContainer>
          </Col>
        </Row>
      </StyledJumbotron>
    </>
  );
};

export default AdvocateForChange;
