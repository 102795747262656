import styled from "styled-components";
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";

export const StyledCard = styled(Card)`
  border: none;
  padding-top: 1rem;

  &:hover {
    cursor: pointer;
  }

  ${({ theme }) => theme.sm`
    min-width: 290px;
    max-width: 290px;
  `};

  ${({ theme }) => theme.xl`
    min-width: 320px;
    max-width: 320px;
  `};
`;

export const StyledCardDeck = styled(CardDeck)`
  justify-content: center;

  ${({ theme }) => theme.lg`
    justify-content: flex-start;
  `};
`;
