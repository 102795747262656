import React from "react";
import PeopleWithFoodAllergies from "../../assets/people-with-food-allergies.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import {
  PageContainer,
  Header,
  SubHeader,
  ListContainer,
  ListItem
} from "../shared.styled.components";

const FactsAndFigures: React.FC = () => {
  return (
    <PageContainer>
      <Header>Facts and Figures</Header>

      <Row className="mt-4">
        <Col md={12} lg={6}>
          <SubHeader>How Many People Do Food Allergies Affect?</SubHeader>
          <ListContainer>
            <ListItem>
              An estimated 32 million Americans live with food allergies.
              <sup>1</sup>
            </ListItem>
            <ListItem>
              One in thirteen children suffers from food allergies, and that
              number is on the rise.<sup>2</sup>
            </ListItem>
            <ListItem>
              Each year in the U.S., 200,000 people require emergency medical
              care for allergic reactions to food.<sup>3</sup>
            </ListItem>
            <ListItem>
              The prevalence of food allergies among children increased 18%
              during 1997– 2007, and allergic reactions to foods have become the
              most common cause of anaphylaxis in community health settings.{" "}
              <sup>2</sup>
            </ListItem>
          </ListContainer>
        </Col>
        <Col md={12} lg={6} className="d-flex flex-wrap align-items-center">
          <Image fluid src={PeopleWithFoodAllergies} />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <SubHeader>Is There a Cure For Food Allergies?</SubHeader>

          <ListContainer>
            <ListItem>
              There is no cure for allergies. You can manage allergies with
              prevention and treatment. It is among the country’s most common,
              but overlooked diseases.<sup>3</sup>
            </ListItem>
          </ListContainer>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <SubHeader>
            Severe Allergic Reactions: How Common Are They and How Are They
            Treated?
          </SubHeader>

          <ListContainer>
            <ListItem>
              Every 3 minutes, a severe allergic reaction to food sends someone
              to the emergency room.<sup>4</sup>
            </ListItem>

            <ListItem>
              Epinephrine must be administered within minutes of anaphylaxis to
              be successful and they are the only available treatment for severe
              allergy reactions.<sup>5</sup>
            </ListItem>
          </ListContainer>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <SubHeader>Are Food Allergies a Concern in Schools?</SubHeader>

          <ListContainer>
            <ListItem>
              Studies show that 16%–18% of children with food allergies have had
              a reaction from accidentally eating food allergens while at
              school.<sup>2</sup>
            </ListItem>

            <ListItem>
              25% of anaphylaxis reactions at school occur in students
              previously undiagnosed with a severe allergy to food, insect
              venom, latex or medication.<sup>2</sup>
            </ListItem>
          </ListContainer>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <SubHeader>References</SubHeader>

          <ListContainer>
            <ListItem>
              [1] Gupta, R. S., Warren, C. M., Smith, B. M., Jiang, J.,
              Blumenstock, J. A., Davis, M. M., Schleimer, R. P., & Nadeau, K.
              C. (2019). Prevalence and Severity of Food Allergies Among US
              Adults. JAMA network open, 2(1), e185630.
              https://doi.org/10.1001/jamanetworkopen.2018.5630
            </ListItem>
            <ListItem>
              [2] CDC.gov. (2018). Food Allergies | Healthy Schools | CDC.
              [online] Available at:
              https://www.cdc.gov/healthyschools/foodallergies/index.htm
              [Accessed 14 Feb. 2018].
            </ListItem>
            <ListItem>
              [3] Asthma and Allergy Foundation of America. (2018) . Allergy
              Facts and Figures. AAFA.
              https://www.aafa.org/allergy-facts/#:~:text=How%20Many%20People%20Get%20Sick,were%20diagnosed%20with%20hay%20fever.&text=People%20visit%20the%20emergency%20room,year%20because%20of%20food%20allergies.
            </ListItem>
            <ListItem>
              [4] Clark, S., Bock, S. A., Gaeta, T. J., Brenner, B. E., Cydulka,
              R. K., Camargo, C. A., & Multicenter Airway Research
              Collaboration-8 Investigators (2004). Multicenter study of
              emergency department visits for food allergies. The Journal of
              allergy and clinical immunology, 113(2), 347–352.
              https://doi.org/10.1016/j.jaci.2003.10.053
            </ListItem>
            <ListItem>
              [5] Kothari, P. (2020). Epinephrine is the only effective
              treatment for anaphylaxis. Harvard Health Blog.
              https://www.health.harvard.edu/blog/epinephrine-is-the-only-effective-treatment-for-anaphylaxis-2020070920523
            </ListItem>
          </ListContainer>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default FactsAndFigures;
