import styled from "styled-components";
import { BODY_FONT_SIZE } from "../../pages/shared.styled.components";
import Card from "react-bootstrap/Card";
import { siteDarkBlue } from "../../providers/Theme";

export const StyledCard = styled(Card).attrs({ className: "mb-5" })`
  max-width: 300px;
  min-width: 200px;
  border: none;
  text-align: center;
`;

export const CardTitle = styled(Card.Title)`
  font-size: ${BODY_FONT_SIZE};
  font-weight: bold;
  color: ${siteDarkBlue};

  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
