import React, { useRef } from "react";

export interface DonateFormProps {
  triggerElement: React.ReactElement;
}

const DonateForm: React.FC<DonateFormProps> = ({ triggerElement }) => {
  const formRef = useRef<HTMLFormElement>(null);

  const donate = () => {
    formRef?.current?.submit();
  };

  return (
    <>
      <form
        ref={formRef}
        action="https://www.paypal.com/donate"
        method="post"
        target="_top"
      >
        <input type="hidden" name="hosted_button_id" value="8V5Y22TES7J8Q" />
      </form>

      {React.cloneElement(triggerElement, { onClick: donate })}
    </>
  );
};

export default DonateForm;
