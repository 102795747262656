import React from "react";
import AboutUsImg from "../../assets/about-us.jpg";
import HerosClimbGroup from "../../assets/heros-climb-group.jpeg";
import CollaboratorsImg from "../../assets/collaborators-thumbnail.png";
import ConnectWithUsImg from "../../assets/connect-with-us-thumbnail.png";
import InTheNewsImg from "../../assets/in-the-news.png";
import AboutUsCard from "../../components/AboutUsCard";
import { siteDarkGreen } from "../../providers/Theme";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { BodyText, StyledJumbotron } from "../shared.styled.components";
import {
  StyledImage,
  StyledRow,
  StyledLink,
  StyledContainer
} from "./styled.components";

const AboutUs: React.FC = () => {
  return (
    <>
      <Row>
        <Col className="d-flex flex-wrap align-items-center" lg={4} sm={12}>
          <StyledImage fluid src={AboutUsImg} />
        </Col>
        <Col className="my-auto" lg={8} sm={12}>
          <StyledContainer>
            <h1>
              <b>Kyah Rayne</b>
            </h1>
            <BodyText>
              Kyah Rayne was born March 17, 1997. The name Kyah, in its African
              root, means "diamond in the sky." In Hindi, Kyah means Aura/Life
              changing and her middle name, Rayne, means "Queen." Keshet, her
              Hebrew name means "rainbow." She was and will always be a Life
              Changing Queen. Kyah was one of those unique bright lights in the
              lives of everyone she knew. She had a quiet, self-assured
              presence. She didn’t need to be the center of attention, but she
              took her place there with ease and assurance. She danced her way
              through life. She required the truth from her family, friends, and
              herself. She was a beauty inside and out. She was and will always
              be my best friend and teacher. She knew who she was and continues
              to show me how to be the best I can be. To live, to love, to tell
              the truth, to dance, to be of service. She was the smartest person
              I've ever met. Kyah Rayne is my daughter.
            </BodyText>

            <StyledLink
              href="https://www.allergicliving.com/thisallergiclife/in-mexico-we-lost-my-girl-to-a-taste-of-peanut/"
              target="_blank"
            >
              Read our story published by Alleric Living
            </StyledLink>
          </StyledContainer>
        </Col>
      </Row>

      <StyledJumbotron backgroundColor={siteDarkGreen}>
        <Container className="text-center">
          <h2>WHY WE ARE HERE</h2>

          <BodyText>
            The prevalence of food allergies among children increased 50%
            between 1997–2011, and allergic reactions to foods have become the
            most common cause of anaphylaxis in community health settings.
          </BodyText>
          <BodyText>
            The Kyah Rayne Foundation is striving to improve the Early Care and
            Education programs (ECE) which should develop plans for preventing
            an allergic reaction and responding to a food allergy emergency,
            including anaphylaxis. Early and quick recognition and treatment can
            prevent serious health problems or death.
          </BodyText>
          <BodyText>
            Studies show that 16%–18% of children with food allergies have had a
            reaction from accidentally eating food allergens while at school. In
            addition, 25% of the severe and potentially life-threatening
            reactions (anaphylaxis) reported at schools happened in children
            with NO previous diagnosis of food allergy.
          </BodyText>
          <BodyText>
            School and ECE program staff should be ready to address the needs of
            children with known food allergies. They also should be prepared to
            respond effectively to the emergency needs of children who are not
            known to have food allergies but who exhibit allergic signs and
            symptoms. Until now, NO national guidelines had been developed to
            help schools and ECE programs address the needs of the growing
            numbers of children with food allergies. Many schools have
            implemented some of the steps needed to manage food allergies
            effectively. Yet systematic planning for managing the risk of food
            allergies and responding to food allergy emergencies in schools and
            ECE programs remain incomplete and inconsistent. Our mission is to
            develop and monitor comprehensive emergency planning that includes
            prevention, preparedness, response, and recovery for food allergy
            and anaphylactic emergencies (including administration of
            epinephrine).
          </BodyText>
        </Container>
      </StyledJumbotron>

      <StyledRow className="mb-4">
        <Container>
          <Row className="d-flex flex-wrap justify-content-center">
            <AboutUsCard
              src={HerosClimbGroup}
              title="Kyah's Story"
              path="/kyahs-story"
            />
            <AboutUsCard
              src={CollaboratorsImg}
              title="Collaborators"
              path="/collaborators"
            />
            <AboutUsCard
              src={ConnectWithUsImg}
              title="Connect With Us"
              path="/connect-with-us"
            />
            <AboutUsCard
              src={InTheNewsImg}
              title="In the News"
              path="/in-the-news"
            />
          </Row>
        </Container>
      </StyledRow>
    </>
  );
};

export default AboutUs;
