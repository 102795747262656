import React from "react";
import { useCustomRouter } from "../../hooks/useCustomRouter";
import { sitePurple } from "../../providers/Theme";
import OutReachImg from "../../assets/outreach.png";
import KrfInternshipDoc from "../../assets/krf-internship-program.pdf";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import {
  PurpleButton,
  StyledRow,
  StyledSubHeader,
  StyledCol,
  BannerTextWrapper,
  StyledLink
} from "./styled.components";
import {
  TopPageContainer,
  Header,
  SubHeader,
  BodyText,
  PageContainer,
  StyledJumbotron,
  ListContainer,
  ListItem,
  JumbotronSubHeader
} from "../shared.styled.components";

const VolunteersAndTrainers: React.FC = () => {
  const customRouter = useCustomRouter();

  const goToOutReachPage = () => {
    customRouter.append("/outreach-call-team");
  };

  const goToExternalUrl = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <TopPageContainer>
        <Header color={sitePurple}>Volunteers and Trainers</Header>
      </TopPageContainer>
      <StyledRow>
        <Row>
          <Container fluid className="text-center mx-3">
            <StyledSubHeader smallScreen>
              Outreach Call Team helps reach Arizona Schools with information
              about Kyah's EPICourse and the SSMP
            </StyledSubHeader>
          </Container>
        </Row>
        <Row className="d-flex flex-wrap align-items-center">
          <StyledCol sm={5} lg={5}>
            <Image fluid src={OutReachImg} />
          </StyledCol>
          <StyledCol sm={7} lg={7}>
            <Container fluid className="px-5">
              <StyledSubHeader largeScreen>
                Outreach Call Team helps reach Arizona Schools with information
                about Kyah's EPICourse and the SSMP
              </StyledSubHeader>

              <BannerTextWrapper className="pt-1">
                <BodyText>
                  KRF is working hard to encourage Arizona public, private and
                  charter schools to take advantage of the School Surveillance
                  and Medication Program (SSMP) through a grassroots effort and
                  the support of the Arizona Department of Health, Arizona
                  Department of Education, Maricopa and Pima County medical
                  directors and superintendents.
                </BodyText>
                <BodyText>
                  Our team of University of Arizona Public Health interns has
                  been working tirelessly all summer and into the fall to reach
                  each of the over 2,000 schools in Pima and Maricopa County. We
                  are excited to share that <b>over 400 schools</b> have
                  responded and taken the first steps for enrollment in the
                  SSMP! Check that your school is enrolled in the SSMP and join
                  us in the effort to get epinephrine auto-injectors on every
                  school campus.
                </BodyText>
              </BannerTextWrapper>

              <PurpleButton onClick={goToOutReachPage}>Read More</PurpleButton>
            </Container>
          </StyledCol>
        </Row>
      </StyledRow>

      <PageContainer className="my-5">
        <SubHeader className="pb-2" color={sitePurple}>
          University of Arizona Internship Program
        </SubHeader>

        <BodyText>
          KRF has leveraged its public-private partnership with the{" "}
          <strong>
            University of Arizona Mel and Enid Zuckerman College of Public
            Health
          </strong>{" "}
          to develop an innovative internship program.
        </BodyText>

        <BodyText>
          KRF has been able to offer internship positions to undergraduate and
          graduate public health students since Summer 2019, providing
          opportunities to gain experience in outreach, health education,
          policy, and leadership.{" "}
          <StyledLink
            onClick={() =>
              goToExternalUrl(
                "https://www.publichealth.arizona.edu/undergraduate/internships"
              )
            }
          >
            Learn More &gt;
          </StyledLink>
        </BodyText>

        <JumbotronSubHeader>Ongoing/Past Projects</JumbotronSubHeader>
        <ListContainer>
          <ListItem>
            Food allergy & epinephrine training course development
          </ListItem>
          <ListItem>
            Program implementation (SSMP: Stock Epinephrine for Schools)
          </ListItem>
          <ListItem>
            Data collection on SSMP enrollment, certification, and stock orders
            for epinephrine auto injectors
          </ListItem>
          <ListItem>
            Evaluation & development of SSMP health promotion materials
          </ListItem>
          <ListItem>Literature reviews</ListItem>
          <ListItem>Social media & blog posts</ListItem>
        </ListContainer>

        <JumbotronSubHeader>Looking for an internship?</JumbotronSubHeader>
        <BodyText>
          UArizona Public Health students typically dedicate 8-20 hours/week to
          KRF related work (flexible according to the student's number of
          enrolled units and required contact hours).
        </BodyText>
        <BodyText>
          More information about our internship program is available{" "}
          <StyledLink onClick={() => goToExternalUrl(KrfInternshipDoc)}>
            here.
          </StyledLink>
        </BodyText>
      </PageContainer>

      <StyledJumbotron className="mb-0" backgroundColor={sitePurple}>
        <Container>
          <h2 className="font-weight-bold">Join Us</h2>

          <BodyText>
            Our mission at KRF is to ensure that communities have the tools they
            need to manage food allergies, including awareness and education
            surrounding food allergies and increased access to Epinephrine
            Auto-Injectors. To reach our goal, we need your passion and
            participation. Deaths from food allergies are preventable, and
            together we can make the world a safer place for people with food
            allergies.
          </BodyText>
        </Container>
      </StyledJumbotron>
    </>
  );
};

export default VolunteersAndTrainers;
