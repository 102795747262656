import React from "react";
import PageTitleBanner from "../../components/PageTitleBanner";
import KyahsStoryBanner from "../../assets/kyahs-story-banner.png";
import KyahsPeakClimb from "../../assets/climbing-kyah-rayne-peak.jpeg";
import KyahsPeakHike from "../../assets/hiking-group-kyah-rayne-peak.jpeg";
import KyahDiploma from "../../assets/kyah-diploma.jpeg";
import KyahGradChair from "../../assets/kyah-grad-chair.jpeg";
import {
  JournalEntryContainer,
  TextWrapper,
  GreenRow,
  TextCol,
  ImgCol
} from "./styled.components";
import { PageContainer, SubHeader } from "../shared.styled.components";
import Container from "react-bootstrap/Container";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";

const KyahsStory: React.FC = () => {
  return (
    <>
      <PageTitleBanner title="Kyah's Story" imageSrc={KyahsStoryBanner} />
      <PageContainer className="mt-5">
        <Container>
          <SubHeader>Indigo Child</SubHeader>
          <p className="font-italic">
            Kyah Rayne’s Journal entry July 13, 2016
          </p>
          <JournalEntryContainer>
            <p>
              I always knew there was magic in my eyes; a power, a force. The
              specialness coming from within the Looking Glass. They change with
              my mood, the seasons, the clothes I cover myself with. I feel the
              power radiating behind them when I look into the sun they are
              sensitive and blurred, but I feel as though they are the inner
              light of my being. Sometimes my stare can tell a story better than
              my mouth can. My eyes reflecting my thoughts without any words
              spoken.
            </p>
            <p>
              This year has held ratifying change; spiritual, emotional,
              physical change. Soulful change. My so-called "awakening",
              becoming my true and false self. It is not that I feel superior.
              It is that I feel special. I am special. I feel that I was put on
              the earth for a reason this life, my life holds a purpose.
            </p>
            <p>
              I always knew there was a magic in my eyes. Indigo magic. I am an
              Indigo Child. Rare and beautiful and strong. Special. Though my
              awakening was recent, I have always had Indigo magic in me. There
              were always signs. As a child, I was sick a lot. I cannot eat most
              things. I was delicate. My hands always shaky. People think it’s a
              nervous tick, my thighs always shaking rapidly under the table.
              It’s not a nervous tick, I am rarely nervous. I shake when I am
              thinking. I am always thinking. I can handle weather better than
              most; my skin being is thicker than most.Why me? I don’t know why.
              I am special. I will live this life as an Indigo Child. I see
              shapes when I close my eyes. I see things.
            </p>
            <p>
              I will never be normal. I will never have a 9-to-5 and I will
              never stick with unhappiness. I will grow. I will wander. I will
              learn. I will teach. I will know when I need to change and when I
              need help. People won’t understand me. Some people will. It is not
              my duty to change people beliefs, it IS my duty to help people.
            </p>
          </JournalEntryContainer>
        </Container>

        <Container>
          <SubHeader className="pt-5">A Hero's Climb</SubHeader>

          <p className="font-italic">Kyah Rayne Peak</p>
          <TextWrapper>
            <p>
              A "Heroes Climb" took Kyah's ashes on an epic journey to name a
              mountain in the Valley of the Gods in her honor. Ten climbers set
              out on this journey in Himalaya Pradesh, India in July, 2019. The
              trek started in the northern town of Kullu. It would take the 10
              climbers 9 days to reach the 16,000 ft summit. The difficulty
              level of the climb was so intense that 3 of the 10 did not summit.
              It was treacherous. It was powerful. It was a labor of love and
              dedication. The climb was unique and recorded in the Hindi Times
              newspaper with much fanfare. Mountains in the Valley are named in
              Hindi. Kyah means Aura (life) changing and Rayne means Queen in
              Hindi. So, our girl will forever have a mountain that bears her
              name in the Valley of the Gods. Kyah Rayne Peak, Life Changing
              Queen.
            </p>
          </TextWrapper>

          <Carousel fade>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={KyahsPeakHike}
                alt="Kyahs peak hike"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={KyahsPeakClimb}
                alt="Kyahs peak climb"
              />
            </Carousel.Item>
          </Carousel>
        </Container>
      </PageContainer>

      <Row className="d-flex flex-wrap align-items-center">
        <TextCol sm={6}>
          <Container>
            <SubHeader>Our Family</SubHeader>
            <TextWrapper>
              <p>
                Very recently, our family accepted Kyah’s diploma from the
                University of Arizona, for a Bachelor’s Degree in Psychology,
                with Honors. It was an important and difficult milestone for our
                family, and reinforced our passion to bring this project to
                light. I will do this work on behalf of my daughter, Kyah Rayne,
                in hopes of making an impact and saving lives.
              </p>
            </TextWrapper>
          </Container>
        </TextCol>

        <ImgCol sm={6}>
          <Image fluid src={KyahDiploma} />
        </ImgCol>
      </Row>

      <GreenRow className="d-flex flex-wrap align-items-center">
        <ImgCol xl={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
          <Image fluid src={KyahGradChair} />
        </ImgCol>

        <TextCol xl={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }}>
          <Container>
            <SubHeader>100 LOVE SONNETS BY PABLO NERUDA</SubHeader>
            <TextWrapper>
              <p>SONNET #94</p>
              <p>
                If I die, survive me with such sheer force that you waken the
                furies of the pallid and the cold, from south to south lift your
                indelible eyes, from sun to sun dream through your singing
                mouth. I don’t want your laughter or your steps to waver, I
                don’t want my heritage of joy to die. Don’t call up my person. I
                am absent. Live in my absence as if in a house. Absence is a
                house so vast that inside you will pass through its walls and
                hang pictures on the air Absence is a house so transparent that
                I, lifeless, will see you, living, and if you suffer, my love, I
                will die again.
              </p>
            </TextWrapper>
          </Container>
        </TextCol>
      </GreenRow>
    </>
  );
};

export default KyahsStory;
