import React from "react";
import { sitePurple } from "../../providers/Theme";
import OutReachImg from "../../assets/outreach.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  StyledContainer,
  StyledHr,
  StyledLink,
  BannerText,
  StyledImage,
  StyledRow
} from "./styled.components";
import { Header, BodyText, PageContainer } from "../shared.styled.components";

const OutreachCallTeam: React.FC = () => {
  return (
    <PageContainer>
      <Header color={sitePurple}>Our Outreach Call Team</Header>

      <Row className="d-flex flex-wrap align-items-center pt-4">
        <Col lg={8}>
          <BodyText>
            With the launch of Kyah’s EPICourse, the Kyah Rayne Foundation has
            enlisted the help of friends and supporters in rolling out the
            course to schools and promoting the School Surveillance Medication
            Program (SSMP) across Arizona!
          </BodyText>
          <BodyText>
            The SSMP is aimed at facilitating Arizona public, private and
            charter schools in stocking and administering Epinephrine Auto
            Injectors (EAIs). Currently, less than 2% of Arizona schools
            implement the SSMP which would increase access to life-saving
            medication for children with food allergies at school. EAIs must be
            administered within minutes of anaphylaxis to be successful and they
            are the only available treatment for severe allergy reactions. This
            program can save lives, while decreasing the amount of emergency
            response calls and EMS transports.
          </BodyText>
        </Col>
        <Col className="d-flex flex-wrap align-items-center" lg={4}>
          <StyledImage fluid src={OutReachImg} />
        </Col>
      </Row>

      <StyledRow>
        <Col>
          <BodyText>
            Through a grassroots effort and the support of the Arizona
            Department of Health, Arizona Department of Education, Maricopa and
            Pima County medical directors and superintendents, KRF is working to
            encourage schools to take advantage of the SSMP. Our UArizona Health
            Promotions interns have spearheaded the establishment of several
            "Outreach Call Teams” to let Arizona public, private and charter
            schools know this program is available to them and help get them
            started in the enrollment process.
          </BodyText>
        </Col>
      </StyledRow>

      <Row>
        <Col>
          <BodyText>
            We are excited to share <b>that over 400</b> schools have responded
            and taken the first steps for enrollment in the{" "}
            <a
              href="https://www.maricopa.gov/5038/Rescue-Medication-Surveillance"
              target="_blank"
              rel="noreferrer"
            >
              SSMP
            </a>
            ! Check that your school is enrolled in the SSMP and join us in the
            effort to get epinephrine auto-injectors on every school campus.
          </BodyText>
        </Col>
      </Row>

      <StyledContainer>
        <StyledHr />
        <BannerText>
          Interested in joining the effort to increase access to life-saving
          medication in schools? Fill out the following{" "}
          <StyledLink
            href="https://forms.gle/DrUwp9Ua5wYAVdTC9"
            target="_blank"
          >
            survey
          </StyledLink>
          .
        </BannerText>
        <StyledHr />
      </StyledContainer>
    </PageContainer>
  );
};

export default OutreachCallTeam;
