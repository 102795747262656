import styled from "styled-components";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { BODY_FONT_SIZE } from "../../pages/shared.styled.components";

export const CardText = styled(Card.Text)`
  font-size: ${BODY_FONT_SIZE};
`;

export const CardTitle = styled(Card.Title)`
  font-size: ${BODY_FONT_SIZE};
  font-weight: bold;
`;

export const StyledImage = styled(Image)`
  height: 250px;
  margin: 0 auto;
  display: block;
`;

export const StyledCol = styled(Col)`
  min-width: 250px;
`;
