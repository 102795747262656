import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { sitePurple, siteGray } from "../../providers/Theme";
import { BODY_FONT_SIZE } from "../../pages/shared.styled.components";
import { SubHeader } from "../shared.styled.components";

export const PurpleButton = styled(Button)`
  background-color: ${sitePurple};
  border: 3px solid ${sitePurple};
  border-radius: 0;

  width: 200px;
  font-size: ${BODY_FONT_SIZE};
  font-weight: bold;
  margin: 0 auto;
  margin-top: auto;
  margin-bottom: 1.5rem;

  &:hover {
    color: ${sitePurple};
    background-color: white;
    border: 3px solid ${sitePurple};
  }
`;

export const StyledRow = styled(Row)`
  background-color: ${siteGray};
  margin-top: 1.5rem;
`;

interface StyledSubHeaderProps {
  smallScreen?: true;
  largeScreen?: true;
}

export const StyledSubHeader = styled(SubHeader)<StyledSubHeaderProps>`
  ${props =>
    props.largeScreen &&
    `
      display: none;
      ${props.theme.lg`
        display: block;
      `}
    `}

  ${props =>
    props.smallScreen &&
    `
        display: block;
        margin-bottom: 1.5rem;
        padding-top: 1.5rem;
        ${props.theme.lg`
          display: none;
        `}
      `}
`;

export const StyledCol = styled(Col)`
  padding: 0;
  ${({ theme }) => theme.lg`
    padding: 0 15px;
  `}

  @media (max-width: 376px) {
    padding: 0 15px;
  }
`;

export const BannerTextWrapper = styled.div`
  padding-top: 1.5rem;
  ${({ theme }) => theme.xs`
    padding: 0;
  `}
`;

export const StyledLink = styled.a`
  font-weight: bold;
  color: ${sitePurple};
  text-decoration: underline;

  &:hover {
    color: ${sitePurple};
    cursor: pointer;
  }
`;
