import styled from "styled-components";
import Card from "react-bootstrap/Card";
import { siteGray, siteDarkGreen } from "../../providers/Theme";

export const StyledCard = styled(Card).attrs({ className: "mb-5" })`
  min-width: 300px;
  max-width: 350px;
  background-color: ${siteGray};

  &:hover {
    cursor: pointer;
  }
`;

export const StyledTitle = styled(Card.Title)`
  text-align: center;
  font-size: 32px;
  font-style: italic;
`;

export const StyledText = styled(Card.Text)`
  color: ${siteDarkGreen};
  text-align: center;
  font-size: 18px;
`;

export const StyledCardImg = styled(Card.Img)`
  padding: 25px;
`;
