import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { VideoContainer } from "./styled.components";
import { PageContainer, Header, BodyText } from "../shared.styled.components";

const Anaphylaxis: React.FC = () => {
  return (
    <PageContainer>
      <Header>Anaphylaxis</Header>

      <Container fluid="md">
        <Row className="justify-content-md-center my-5">
          <Col>
            <VideoContainer>
              <iframe
                className="embed-responsive-item"
                src="https://player.vimeo.com/video/748510991?h=b58523b834"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
              />
            </VideoContainer>
          </Col>
        </Row>
      </Container>

      <BodyText>
        Anaphylaxis (pronounced an-uh-fil-LAX-is) is a severe, potentially
        life-threatening allergic reaction. It is a medical condition occurring
        in allergic individuals after exposure to triggers such as certain
        foods, medicine, latex, or an insect sting. Symptoms can affect several
        areas of the body, including breathing and blood circulation.
      </BodyText>

      <BodyText>
        An allergic reaction can cause anaphylaxis, a serious complication that
        if not quickly treated with epinephrine, can result in death in as
        little as 30 minutes.
      </BodyText>

      <BodyText>
        Early use of an epinephrine auto-injector is the primary treatment for
        anaphylaxis.
      </BodyText>
    </PageContainer>
  );
};

export default Anaphylaxis;
