import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import {
  StyledCard,
  StyledTitle,
  StyledText,
  StyledCardImg
} from "./styled.components";

interface CollaboratorCardProps {
  src: string;
  title: string;
  content: string;
  link: string;
}

const CollaboratorCard: React.FC<CollaboratorCardProps> = ({
  src,
  title,
  content,
  link
}) => {
  const goToExternalUrl = () => {
    window.open(link, "_blank");
  };

  return (
    <StyledCard onClick={goToExternalUrl} className="pb-4">
      <Container fluid>
        <StyledCardImg variant="top" src={src} />
      </Container>
      <Card.Body>
        <Container>
          <StyledTitle className="mb-4">{title}</StyledTitle>
          <StyledText>{content}</StyledText>
        </Container>
      </Card.Body>
    </StyledCard>
  );
};

export default CollaboratorCard;
