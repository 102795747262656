import styled from "styled-components";

export const StyledLink = styled.a`
  font-weight: bold;
  color: black;
  text-decoration: underline;

  &:hover {
    color: black;
    cursor: pointer;
  }
`;
