import React from "react";
import AllergicLivingLogo from "../../assets/allergic-living-logo.png";
import FareLogo from "../../assets/collaborators/fare-logo.png";
import SpokinLogo from "../../assets/collaborators/spokin-logo.png";
import UOfACollegeOfPublicHealthLogo from "../../assets/collaborators/ua-coph.png";
import WRPHTCLogo from "../../assets/collaborators/WRPHTC.png";
import RiesterLogo from "../../assets/collaborators/riester.jpg";
import AANLogo from "../../assets/collaborators/AAN-logo.png";
import CertistarLogo from "../../assets/collaborators/certistar-logo.png";
import PCDHLogo from "../../assets/collaborators/PCDH-logo.png";
import MCDPHLogo from "../../assets/collaborators/maricopa-public-health.png";
import EqualEatsLogo from "../../assets/collaborators/equal-eats-logo.png";
import FoxRestaurantConceptLogo from "../../assets/collaborators/fox-restaurant-concepts-logo.png";
import CampNotAWheezeLogo from "../../assets/collaborators/camp-not-a-wheeze-logo.png";
import CollaboratorCard from "../../components/CollaboratorCard";
import CardDeck from "react-bootstrap/CardDeck";
import Container from "react-bootstrap/Container";
import { siteDarkGreen } from "../../providers/Theme";
import { PageContainer, Header } from "../shared.styled.components";

const Collaborators: React.FC = () => {
  return (
    <PageContainer>
      <Container className="text-center my-5">
        <Header color={siteDarkGreen}>Meet Our Collaborators</Header>
      </Container>

      <CardDeck className="d-flex flex-wrap justify-content-center">
        <CollaboratorCard
          src={UOfACollegeOfPublicHealthLogo}
          title="Mel & Enid Zuckerman College of Public Health"
          content="The UA Zuckerman College of Public Health provides both undergraduate and graduate education in population-based health disciplines designed to prepare students for professional and academic public-health careers."
          link="https://publichealth.arizona.edu/"
        />

        <CollaboratorCard
          src={MCDPHLogo}
          title="Maricopa County Department of Public Health"
          content="Our mission at Maricopa County Public Health is to protect and promote the health and well-being of all of our residents and visitors."
          link="https://www.maricopa.gov/5302/Public-Health"
        />

        <CollaboratorCard
          src={PCDHLogo}
          title="Pima County Health Department"
          content="The Pima County Health Department is dedicated to help the residents of Pima County achieve and maintain an optimal level of wellness."
          link="https://webcms.pima.gov/government/health_department/"
        />

        <CollaboratorCard
          src={WRPHTCLogo}
          title="Western Region Public Health Training Center"
          content="The Western Region Public Health Training Center is a Continuing Professional Education (CPE) Accredited Provider with the Commission on Dietetic Registration (CDR)."
          link="https://wrphtc.arizona.edu/"
        />

        <CollaboratorCard
          src={FareLogo}
          title="FARE"
          content="FARE works to enhance the access of individuals with food allergies to safe food, effective emergency treatment and accurate health information."
          link="https://www.foodallergy.org/"
        />

        <CollaboratorCard
          src={AllergicLivingLogo}
          title="Allergic Living"
          content="The authoritative voice on food allergy news."
          link="https://www.allergicliving.com/"
        />

        <CollaboratorCard
          src={RiesterLogo}
          title="Riester Foundation"
          content="RIESTER launches and revitalizes brands, changes consumer behaviors and motivates people to act on issues that matter. The RIESTER Foundation cares about protecting earth’s unique creatures and their environments."
          link="https://www.riester.com/"
        />

        <CollaboratorCard
          src={CertistarLogo}
          title="Certistar"
          content="Take the guesswork out of food allergies. A mission to protect and improve the safety and dining experience of men, women and children vulnerable to food allergens."
          link="https://certistar.com/"
        />

        <CollaboratorCard
          src={SpokinLogo}
          title="Spokin"
          content="Meet the best food allergy app ever. Spokin saves you time to make life with food allergies easier."
          link="https://www.spokin.com/"
        />

        <CollaboratorCard
          src={AANLogo}
          title="Allergy & Asthma Network"
          content="Allergy & Asthma Network unites and advocates on behalf of 60 million Americans with asthma, allergies and related conditions."
          link="https://allergyasthmanetwork.org/"
        />

        <CollaboratorCard
          src={FoxRestaurantConceptLogo}
          title="Fox Restaurant Concepts"
          content="Fox Restaurant Concepts believes the best stories are shared over food. We challenge ourselves to find ways to make our best ideas even better and our people and guests are at the heart of all we do."
          link="https://www.foxrc.com/"
        />

        <CollaboratorCard
          src={EqualEatsLogo}
          title="Equal Eats"
          content="Our mission is to provide solutions and tools to help people living with dietary restrictions communicate with the food service industry effectively, accurately, and conveniently."
          link="https://equaleats.com/"
        />

        <CollaboratorCard
          src={CampNotAWheezeLogo}
          title="Camp Not-A-Wheeze"
          content="Celebrating more than 35 years, Camp Not-A-Wheeze is the premiere camp for children with asthma or allergic conditions in the state of Arizona. In a traditional camp setting asthma and allergy education is integrated with recreational camp activities. This empowers children to exercise control over their allergy or chronic lung condition."
          link="https://www.campnotawheeze.org"
        />
      </CardDeck>
    </PageContainer>
  );
};

export default Collaborators;
