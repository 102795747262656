import React from "react";
import { BodyText, SubHeader } from "../../pages/shared.styled.components";
import Container from "react-bootstrap/Container";
import { siteDarkGreen } from "../../providers/Theme";
import Newsletter, { NewsletterProps } from "../Newsletter";

interface NewsletterCategoryProps {
  title: string;
  subText: string;
  newsletters: NewsletterProps[];
}

const NewsletterCategory: React.FC<NewsletterCategoryProps> = ({
  title,
  subText,
  newsletters
}) => {
  return (
    <>
      <Container fluid className="my-4 ml-3">
        <SubHeader color={siteDarkGreen}>{title}</SubHeader>
        <BodyText>{subText}</BodyText>
      </Container>

      {newsletters.map(newsletter => (
        <Newsletter
          title={newsletter.title}
          src={newsletter.src}
          link={newsletter.link}
        />
      ))}
    </>
  );
};

export default NewsletterCategory;
