import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";

interface StyledButtonProps {
  color: string;
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  background-color: ${props => props.color};
  border: none;
  font-weight: bold;
  font-size: 1.5rem;

  text-align: center;

  min-height: 150px;

  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  &:hover {
    background-color: ${props => props.color};
    border: none;
    text-decoration: underline;

    filter: brightness(85%);
  }

  &:active {
    background-color: ${props => props.color} !important;
    border: none;
  }

  width: 100%;
  margin-bottom: 2.5rem;

  ${({ theme }) => theme.md`
    margin-bottom: 0 !important;
  `}
`;

export const StyledCol = styled(Col)`
  p {
    margin-bottom: 0 !important;
  }
`;
