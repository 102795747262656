import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import { sitePurple } from "../../providers/Theme";

export const StyledHr = styled.hr`
  border: 1px dotted white;
  border-style: none none dotted;
  color: ${sitePurple};
  background-color: ${sitePurple};
`;

export const StyledLink = styled.a`
  font-size: 1.5rem;
  color: ${sitePurple};
  text-decoration: underline;

  &:hover {
    color: ${sitePurple};
  }
`;

export const StyledContainer = styled(Container)`
  text-align: center;
  color: ${sitePurple};
`;

export const BannerText = styled.h4`
  font-weight: bold;
`;

export const StyledImage = styled(Image).attrs({ className: "mb-2" })`
  max-height: fit-content;
  margin: 0 auto;
  ${({ theme }) => theme.lg`
    max-height: 335px;
  `};
`;

export const StyledRow = styled(Row)`
  ${({ theme }) => theme.xs`
    padding-top: 1.5rem;
  `};

  ${({ theme }) => theme.lg`
    padding-top: 0px;
  `};
`;
