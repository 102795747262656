import React from "react";
import Row from "react-bootstrap/Row";
import LogoWhite from "../../assets/kyahrayne-1c-tm-white-cropped.png";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube
} from "react-icons/fa";
import {
  StyledFooter,
  StyledImage,
  StyledCol,
  SocialMediaLink
} from "./styled.components";
import { INSTAGRAM_URL } from "../../constants";

const Footer: React.FC = () => {
  return (
    <StyledFooter className="text-center">
      <Row className="h-100">
        <StyledCol>
          <StyledImage className="mx-auto" fluid src={LogoWhite} />
        </StyledCol>
        <StyledCol sm={6}>
          &copy; Copyright {new Date().getFullYear()}. KyahRayneFoundation.org.
          All Rights Reserved.
        </StyledCol>
        <StyledCol>
          <SocialMediaLink href="https://www.facebook.com/Kyah-Rayne-Foundation-105676284120626">
            <FaFacebookF />
          </SocialMediaLink>

          <SocialMediaLink href={INSTAGRAM_URL}>
            <FaInstagram />
          </SocialMediaLink>

          <SocialMediaLink href="https://twitter.com/KyahRayne">
            <FaTwitter />
          </SocialMediaLink>

          <SocialMediaLink href="https://www.linkedin.com/company/kyah-rayne-foundation">
            <FaLinkedinIn />
          </SocialMediaLink>

          <SocialMediaLink href="https://www.youtube.com/channel/UCxf-Wwjgu0sqDEP2kyfTUpQ">
            <FaYoutube />
          </SocialMediaLink>
        </StyledCol>
      </Row>
    </StyledFooter>
  );
};

export default Footer;
