import React, { useState } from "react";
import { useCustomRouter } from "../../hooks/useCustomRouter";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import PagesConfig, {
  Page as PageConfig,
  NavItem as NavItemConfig
} from "../../pages-config";
import {
  NavItem,
  NavLink,
  Logo,
  StyledBreadcrumb,
  BreadcrumbItem,
  DonateBreadcrumb,
  StyledNavDropdown
} from "./styled.components";

const NavigationBar: React.FC = () => {
  const customRouter = useCustomRouter();

  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const pageConfigs = Object.values(PagesConfig);
  const rootPage =
    pageConfigs.find(pg => pg.path === customRouter.rootPath) ||
    PagesConfig.home;

  const [activePage, setActivePage] = useState<PageConfig>(rootPage);

  const goToPage = (page: PageConfig) => {
    setActivePage(page);
    customRouter.push(page.path);
    setExpanded(false);
  };

  const navItems: NavItemConfig[] = Object.values(PagesConfig).filter(
    (config: PageConfig): config is NavItemConfig => !!config.showOnNavBar
  );

  return (
    <>
      <Navbar
        expanded={expanded}
        onSelect={() => setExpanded(false)}
        expand="lg"
      >
        <Nav className="flex-column">
          <Navbar.Brand>
            <Logo onClick={() => goToPage(PagesConfig.home)} />
          </Navbar.Brand>
        </Nav>

        <Navbar.Toggle
          onClick={toggleExpanded}
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse className="mt-auto pb-50" id="basic-navbar-nav">
          <Nav
            fill
            className="w-100"
            activeKey={activePage.key}
            onSelect={selectedKey =>
              goToPage(PagesConfig[selectedKey || "home"])
            }
          >
            {navItems.map((config: NavItemConfig) => (
              <NavItem key={config.key}>
                {config.navConfig.displayAs === "Link" ? (
                  <NavLink eventKey={config.key} color={config.navConfig.color}>
                    {config.title}
                  </NavLink>
                ) : (
                  <StyledNavDropdown
                    active={activePage.key === config.key}
                    renderMenuOnMount={true}
                    color={config.navConfig.color}
                    title={config.title}
                    id={`${config.key}-dropdown`}
                    onClick={(event: React.BaseSyntheticEvent) =>
                      // Only trigger goToPage for nav drop down title element, otherwise use default behavior
                      event?.target?.id === `${config.key}-dropdown` &&
                      goToPage(PagesConfig[config.key])
                    }
                  >
                    {config.navConfig.children.map(childKey => (
                      <NavDropdown.Item
                        className="w-100 px-0"
                        eventKey={childKey}
                        key={`${childKey}-dropdown-item`}
                      >
                        {PagesConfig[childKey].title}
                      </NavDropdown.Item>
                    ))}
                  </StyledNavDropdown>
                )}
              </NavItem>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <StyledBreadcrumb>
        {customRouter.breadCrumbs.map((breadCrumb, idx) => (
          <BreadcrumbItem
            key={`breadcrumb-${idx}`}
            active={idx === customRouter.breadCrumbs.length - 1} // Last element is not active
            onClick={() => goToPage(PagesConfig[breadCrumb.key])}
          >
            {breadCrumb.title}
          </BreadcrumbItem>
        ))}

        {activePage.key !== PagesConfig.donate.key && (
          <DonateBreadcrumb onClick={() => goToPage(PagesConfig.donate)}>
            Donate
          </DonateBreadcrumb>
        )}
      </StyledBreadcrumb>
    </>
  );
};

export default NavigationBar;
