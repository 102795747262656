import styled from "styled-components";
import Col from "react-bootstrap/Col";

export const StyledCol = styled(Col).attrs({ sm: 6, className: "mb-2" })``;

export const DownLoadLink = styled.a`
  color: black;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: black !important;
  }
`;
