import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { siteGray } from "../../providers/Theme";

interface ImageBannerProps {
  src: string;
  height?: string;
}

export const ImageBanner = styled(Row)<ImageBannerProps>`
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;

  ${props => props.height && `height: ${props.height};`};

  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.05);

  padding: 2rem 1rem;

  ${({ theme }) => theme.sm`
    padding: 4rem 2rem;
   `}
`;

export const FlyerLink = styled.a`
  color: black;
  font-weight: bold;

  &:hover {
    color: black;
  }
`;

export const VideoContainer = styled.div.attrs({
  className: "embed-responsive embed-responsive-16by9 mx-auto"
})`
  top: 0;
  left: 0;
  clip-path: inset(2px 2px);
  ${({ theme }) => theme.sm`
      width: 75%;
   `}
`;

export const StyledContainer = styled(Container)`
  background-color: ${siteGray};
  color: black;
`;
