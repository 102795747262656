import React from "react";
import Card from "react-bootstrap/Card";
import { StyledCard, CardText, CardTitle } from "./styled.components";

export interface MedicalAdvisoryBoardCardProps {
  src: string;
  title: string;
  content: string[];
}

const MedicalAdvisoryBoardCard: React.FC<MedicalAdvisoryBoardCardProps> = ({
  src,
  title,
  content
}) => {
  return (
    <StyledCard>
      <Card.Img variant="top" src={src} />
      <Card.Body>
        <CardTitle>{title}</CardTitle>
        {content.map(item => (
          <CardText>{item}</CardText>
        ))}
      </Card.Body>
    </StyledCard>
  );
};

export default MedicalAdvisoryBoardCard;
