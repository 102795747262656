import React from "react";
import { PageContainer, Header, BodyText } from "../shared.styled.components";
import { StyledLink } from "./styled.components";

const EpinephrineFAQ: React.FC = () => {
  return (
    <PageContainer>
      <Header>Epinephrine FAQ</Header>

      <BodyText>
        <b>How does an EAI (EAI Epinephrine Auto-Injector) work?</b> EAI is an
        injection containing Epinephrine a chemical that narrows blood vessels
        and open his airways in the lungs. These effects can reverse severe low
        blood pressure, wheezing, severe skin itching, hives and other symptoms
        of an allergic reaction. The drug in an EAI (EAI) constricts blood
        vessels to increase blood pressure, relaxes smooth muscles in the lungs
        to reduce wheezing and improve breathing, stimulates the heart,
        increases the heart rate and works to reduce hives and swelling‘s that
        may occur around face and lips.
      </BodyText>

      <BodyText>
        <b>How do I administer an EAI?</b> The EAI packaging list clear
        instructions and diagrams on the label. Hold the injector in your fist
        with the needle tip (usually orange or red) away from your thumb and
        placed needle and firmly against the outer thigh upper leg. Hold EAI
        firmly in place for 10 seconds.
      </BodyText>

      <BodyText>
        <b>Should I call 911 after EAI? </b>Yes. Using EAI does not replace a
        doctor or going to the hospital. The effects of Epinephrine can wear off
        or you could have a second reaction so call 911.
      </BodyText>

      <BodyText>
        <b>Can I use an EAI on a different body part besides the thigh?</b> No.
        The EAI should only be injected into the middle of your outer thigh
        upper leg through clothing if necessary. Do not inject into your veins,
        buttocks, fingers, toes, hands or feet.
      </BodyText>

      <BodyText>
        <b>Is a dose of epinephrine deadly?</b> A dose of Epinephrine is a
        relatively mild reaction. Epinephrine increases blood pressure and can
        trigger heart arrhythmias strokes and heart attacks. Other reactions
        aren’t life-threatening including nausea, vomiting, diarrhea.
        Epinephrine is safe for anaphylaxis when given at the correct dose by
        intramuscular injection. The majority of dosing errors and
        cardiovascular adverse reactions occur when epinephrine is given
        intravenously or incorrectly dosed.
      </BodyText>

      <BodyText>
        <b>Can EAI’s only be used by doctors or medical professioanals?</b> No.
        The EAI is designed to be used by people with no medical training at the
        first signs of anaphylactic reaction. Epinephrine is frequently
        administered by non-medically trained individuals. This single use
        injection is designed to be used by people with no medical training.
      </BodyText>

      <BodyText>
        <b>Can I use someone else’s EAI?</b> A person either needs an EAI or
        they don’t. Using one persons EAI is not going to deliver a different
        version of the dose.Is there an EAI autoinjector for all ages? EAI
        autoinjector‘s contain a single dose of 0.3 MG of epinephrine,
        appropriate for people weighing 66 pounds or more. EAI Junior
        auto-injectors contain 0.15 mg of epinephrine and is for children with
        severe allergies weighing 33 to 66 pounds.
      </BodyText>

      <BodyText>
        <b>Is there an EAI autoinjector for all ages?</b> EAI autoinjector‘s
        contain a single dose of 0.3 MG of epinephrine, appropriate for people
        weighing 66 pounds or more. EAI Junior auto injectors contain 0.15 mg of
        epinephrine and is for children with severe allergies weighing 33 to 66
        pounds.
      </BodyText>

      <BodyText>
        <b>Is there EAI for babies?</b> There is a dosages of epinephrine in 0.3
        for children under 30 pounds. Your healthcare professional will
        carefully determine the appropriate dose of epinephrine for your child.
        Be sure to hold the leg of a young child firmly in place before and
        during the injection to prevent injuries.
      </BodyText>

      <BodyText>
        <b>How many EAI should I carry?</b> Two EAI should be carried at all
        times.
      </BodyText>

      <BodyText>
        <b>How long is the EAIs needle?</b> Needle length is 16 mm for an EAI
        and 13 mm for EAI Junior. How long does the EAI last? Effects of an EAI
        last 10 to 20 minutes.
      </BodyText>

      <BodyText>
        <b>How do I dispose of the EAI?</b> Take your used auto-injector to an
        emergency room or healthcare professionals office for proper disposal
        ask for a new disk prescription of an EAI.
      </BodyText>

      <BodyText>
        <b>What happens if I get a dose of Epinephrine when not needed?</b>{" "}
        Accidental injunction into the hands or feet may result in loss of blood
        flow to the affected area and should be avoided if there is an
        accidental injection into these areas go immediately to the nearest
        emergency room for treatment.
      </BodyText>

      <BodyText>
        <b>Can you deliver an expired EAI?</b> Expired EAI’s may still save a
        life. EAI devices used to rescue people during severe allergic reactions
        can remain effective years after their expiration date. See health day
        news.
      </BodyText>

      <BodyText>
        <b>What Epinephrine Auto-Injector’s are available?</b> AdrenaClick,
        Auvi-Q, Symjepi, EAI, EAI Jr.
      </BodyText>

      <BodyText>
        <b>Is an EAI better than the alternatives?</b> The only difference
        between an authorized generic and it’s brand is the name on the label.
        Mylan controls 90% of the market and faces little competition for EAI.
      </BodyText>

      <BodyText>
        <b>How much is an EAI?</b> 2-Pac auto-injectors cost roughly $650-$700.
      </BodyText>

      <BodyText>
        <b>Why is EAI so expensive?</b> Concerns exist that this drug is not
        priced fairly for patients with life-threatening allergies. Over the
        last decade the EAI cost has soared from roughly $100 to over $600 per
        two injector package. The generic versions of both EAI and Adrenaclick
        are made by the same manufacture. In August 2017, Mylan reached a $465
        million settlement with the US Justice Department for overcharging the
        US government for EAIs.{" "}
        <StyledLink
          target="_blank"
          href="https://www.cnbc.com/2017/08/17/mylan-finalizes-465-million-epipen-settlement-with-justice-department.html"
        >
          Read more.
        </StyledLink>
      </BodyText>
    </PageContainer>
  );
};

export default EpinephrineFAQ;
