import React from "react";
import { StyledCardDeck, StyledCard } from "./styled.components";
import { SubHeader, BlueLine } from "../../pages/shared.styled.components";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

interface TrainingToolkit {
  text: string;
  file: string;
  thumbnail: string;
}

interface TrainingToolkitsProps {
  header: string;
  trainingToolkits: TrainingToolkit[];
}

const TrainingToolkits: React.FC<TrainingToolkitsProps> = ({
  header,
  trainingToolkits
}) => {
  const openDocumentInNewTab = (file: string) => {
    window.open(file, "_blank");
  };

  return (
    <>
      <SubHeader className="mt-5">{header}</SubHeader>
      <BlueLine />

      <StyledCardDeck>
        {trainingToolkits.map((toolkit, idx) => (
          <StyledCard
            key={`training-toolkit-${idx}`}
            onClick={() => openDocumentInNewTab(toolkit.file)}
          >
            <Container fluid>
              <Card.Img variant="top" src={toolkit.thumbnail} />
            </Container>
            <Card.Body>
              <Card.Text>{toolkit.text}</Card.Text>
            </Card.Body>
          </StyledCard>
        ))}
      </StyledCardDeck>
    </>
  );
};

export default TrainingToolkits;
