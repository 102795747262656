import React from "react";
import { useCustomRouter } from "../../hooks/useCustomRouter";
import EpiProductsAvailableImg from "../../assets/epinephrine-products-available.png";
import PossibleSideEffectsImg from "../../assets/possible-side-effects.png";
import HowToGetImg from "../../assets/how-to-get.png";
import StorageImg from "../../assets/storage.png";
import DisposalImg from "../../assets/disposal.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Image from "react-bootstrap/Image";
import {
  PageContainer,
  Header,
  BodyText,
  SubHeader,
  ListContainer,
  ListItem,
  StyledJumbotron,
  TopPageContainer
} from "../shared.styled.components";
import {
  ImageContainer,
  BlueItalicText,
  NoMarginBodyText,
  StyledTabs,
  FAQLink,
  TabsContainer,
  TabBody,
  VideoContainer
} from "./styled.components";

const Epinephrine: React.FC = () => {
  const customRouter = useCustomRouter();

  return (
    <>
      <TopPageContainer>
        <Header>Epinephrine</Header>

        <SubHeader>What is epinephrine?</SubHeader>

        <ListContainer>
          <ListItem>
            Epinephrine is the FIRST and MOST important treatment.
          </ListItem>
          <ListItem>
            Epinephrine works to stop anaphylaxis by relaxing the muscles in the
            airways and by constricting the blood vessels to prevent swelling.
          </ListItem>
          <ListItem>
            Epinephrine is the <u>most effective</u> drug for treating
            anaphylaxis and preventing death.
          </ListItem>
          <ListItem>
            Antihistamines, such as Benadryl, are commonly overused and do not
            act fast enough to treat anaphylaxis.
          </ListItem>
        </ListContainer>

        <Row className="my-5">
          <Col md={12} lg={6} className="d-flex flex-wrap align-items-center">
            <ImageContainer>
              <Image fluid src={EpiProductsAvailableImg} />
            </ImageContainer>
          </Col>
          <Col md={12} lg={6} className="mt-4">
            <SubHeader>What is an EAI?</SubHeader>
            <BodyText>
              Epinephrine auto-injectors or EAIs are produced by a number of
              pharmaceutical companies in the U.S. There are a number of
              pharmaceutical drug companies in the U.S. that produce EAIs.
              Today, most of these EAIs are shaped like a pen with the exception
              of Auvi-Q and Symjepi. Understanding what EAIs are and how they
              work will help you respond to someone who may be experiencing a
              severe allergic reaction or anaphylaxis.
            </BodyText>
          </Col>
        </Row>
      </TopPageContainer>

      <Container fluid="md">
        <Row className="justify-content-md-center my-5">
          <Col>
            <SubHeader className="mb-4">
              Watch the video below to learn more about an EAI.
            </SubHeader>
            <VideoContainer>
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube-nocookie.com/embed/wzmDb2gseWk?controls=0"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
              />
            </VideoContainer>
          </Col>
        </Row>
      </Container>

      <StyledJumbotron>
        <Container className="text-center">
          <h1>
            <b>The #1 reason why anaphylaxis can cause death:</b> epinephrine
            was NOT given fast enough.
          </h1>
        </Container>
      </StyledJumbotron>

      <PageContainer>
        <SubHeader>IMPORTANT</SubHeader>

        <BodyText>
          If you think someone is having a severe allergic reaction, do not wait
          to see if the sign and symptoms get worse and when in doubt always
          give epinephrine.
        </BodyText>

        <NoMarginBodyText>Remember this phrase:</NoMarginBodyText>
        <BlueItalicText>
          Epi first, Epi fast, and when in doubt ALWAYS give Epi
        </BlueItalicText>

        <SubHeader>Facts about EAIs</SubHeader>

        <TabsContainer className="p-0">
          <StyledTabs defaultActiveKey="possible-side-effects">
            <Tab
              className="pb-5"
              eventKey="possible-side-effects"
              title="Possible Side Effects"
            >
              <TabBody>
                <BodyText>
                  Possible life threatening side effects include: skin redness,
                  swelling, warmth, or tenderness at the site of injection,
                  increased heart rate, nausea, vomiting, seating, dizziness.
                </BodyText>

                <BodyText>
                  <b>REMEMBER:</b> The benefits of receiving epinephrine are far
                  greater than the risk of side effects.
                </BodyText>

                <Container>
                  <Image fluid src={PossibleSideEffectsImg} />
                </Container>
              </TabBody>
            </Tab>
            <Tab className="pb-5" eventKey="how-to-get" title="How To Get">
              <TabBody>
                <BodyText>
                  <b>For Patients/Students:</b> It requires a prescription from
                  a physician and comes as 2 single-dose disposable devices. It
                  is important for people with food allergies to always carry 2
                  devices. Remind students to always carry an extra device with
                  them at all times.
                </BodyText>

                <BodyText>
                  <b>For Schools:</b> EAIs are a stock medication so the
                  prescription is not written for a specific patient but for the
                  school's emergency use. Once you complete this training
                  course, your school can then apply for a stock epinephrine
                  prescription.
                </BodyText>

                <Container>
                  <Image fluid src={HowToGetImg} />
                </Container>
              </TabBody>
            </Tab>
            <Tab className="pb-5" eventKey="storage" title="Storage">
              <TabBody>
                <ListContainer>
                  <ListItem>DO NOT refrigerate.</ListItem>
                  <ListItem>
                    Store EAIs at room temperature away from light, excess heat,
                    and moisture (i.e. not in the bathroom or in the bus glove
                    box).
                  </ListItem>
                  <ListItem>
                    Keep EAIs in their plastic carrying tubes, tightly closed,
                    until needed.
                  </ListItem>
                </ListContainer>

                <Container>
                  <Image fluid src={StorageImg} />
                </Container>
              </TabBody>
            </Tab>

            <Tab className="pb-5" eventKey="disposal" title="Disposal">
              <TabBody>
                <BodyText>
                  Always check to make sure your EAI has not expired, become
                  discolored, or contain particles. If you must dispose of an
                  EAI, please try to obtain a replacement before doing so.
                </BodyText>

                <u>How to dispose:</u>

                <ListContainer>
                  <ListItem>Do NOT flush down the toilet.</ListItem>
                  <ListItem>Discard in a sharps container.</ListItem>
                  <ListItem>Look for medicine take-back programs.</ListItem>
                </ListContainer>

                <Container>
                  <Image fluid src={DisposalImg} />
                </Container>
              </TabBody>
            </Tab>
          </StyledTabs>
        </TabsContainer>

        <SubHeader className="pt-4">You should know:</SubHeader>

        <ol>
          <ListItem>Epinephrine is generally a safe treatment to use.</ListItem>
          <ListItem>
            There are some side effects but the benefits outweigh the risks.
          </ListItem>
          <ListItem>You are protected by the Good Samaritan Laws.</ListItem>
        </ol>

        <FAQLink onClick={() => customRouter.append("/epinephrine-faq")}>
          Epinephrine FAQ &gt;
        </FAQLink>
      </PageContainer>
    </>
  );
};

export default Epinephrine;
