import styled from "styled-components";
import { siteDarkGreen } from "../../providers/Theme";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BODY_FONT_SIZE } from "../../pages/shared.styled.components";

export const JournalEntryContainer = styled(Container).attrs({
  className: "p-4"
})`
  background-color: ${siteDarkGreen};
  color: white;
  font-style: italic;
  font-size: ${BODY_FONT_SIZE};
`;

export const TextWrapper = styled.div`
  font-size: ${BODY_FONT_SIZE};
`;

export const GreenRow = styled(Row)`
  background-color: ${siteDarkGreen};
  color: white;
`;

export const TextCol = styled(Col).attrs({ className: "p-5" })``;
export const ImgCol = styled(Col).attrs({ className: "p-0" })``;
