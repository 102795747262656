import styled from "styled-components";
import { siteDarkBlue, siteLightBlue } from "../providers/Theme";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";

export const BODY_FONT_SIZE = "18px;";

export const PageContainer = styled(Container).attrs({ className: "mb-5" })``;

export const TopPageContainer = styled(PageContainer)`
  margin-bottom: 0 !important;
`;

export const Header = styled.h1.attrs({ className: "mt-4" })`
  color: ${props => props.color || siteDarkBlue};
  font-weight: 900;
`;

interface SubHeaderProps {
  color?: string;
}

export const SubHeader = styled.h3<SubHeaderProps>`
  font-weight: bold;
  ${props => props.color && `color: ${props.color}`};
`;

export const BodyText = styled.p.attrs({ className: "mb-4" })`
  font-size: ${BODY_FONT_SIZE};
`;

export const ListContainer = styled.ul`
  word-wrap: break-word;
`;

export const ListItem = styled.li`
  font-size: ${BODY_FONT_SIZE};
`;

export const StyledLink = styled.a`
  color: ${siteDarkBlue};
`;

interface StyledJumbotronProps {
  backgroundColor?: string;
  color?: string;
}

export const StyledJumbotron = styled(Jumbotron)<StyledJumbotronProps>`
  background-color: ${props => props.backgroundColor || siteDarkBlue};
  color: ${props => props.color || "white"};
  padding: 2rem 1rem;
  border-radius: 0;
`;

interface LightButtonProps {
  color: string;
}

export const LightButton = styled(Button).attrs({
  variant: "outline-light"
})<LightButtonProps>`
  border: 3px solid;
  font-size: ${BODY_FONT_SIZE};
  padding: 5px 50px;
  border-radius: 0;

  &:hover {
    color: ${props => props.color};
  }
`;

export const JumbotronHeader = styled.h1.attrs({ className: "mx-auto mb-4" })`
  font-weight: bold;
`;

export const JumbotronSubHeader = styled.h4`
  font-weight: bold;
`;

export const BlueLine = styled.hr`
  border: none;
  height: 5px;

  /* Set the hr color */
  color: ${siteLightBlue}; /* old IE */
  background-color: ${siteLightBlue}; /* Modern Browsers */
`;
