import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import RisksGraphic from "../../assets/risks-graphic.png";
import FoodAllergyReactionImg from "../../assets/food-allergy-reaction.jpg";
import FareEmergencyActionPlan from "../../assets/fare-emergency-care-plan.pdf";
import {
  PageContainer,
  Header,
  BodyText,
  SubHeader,
  ListContainer,
  ListItem,
  StyledLink,
  StyledJumbotron,
  TopPageContainer
} from "../shared.styled.components";
import { ImageContainer } from "./styled.components";

const FoodAllergyRisks: React.FC = () => {
  return (
    <>
      <TopPageContainer>
        <Header>Food Allergy Risks</Header>

        <SubHeader>There are many risks for food allergies</SubHeader>

        <BodyText>
          Every food allergy can potentially turn into anaphylaxis. Reactions
          can look different every time. Even if a person has only had a mild
          reaction before, it can be a severe reaction the next time.
        </BodyText>

        <Row>
          <Col md={12} lg={6} className="d-flex flex-wrap align-items-center">
            <ImageContainer>
              <Image fluid src={RisksGraphic} />
            </ImageContainer>
          </Col>
          <Col md={12} lg={6}>
            <BodyText>
              Factors that can increase a persons's risk of developing a food
              allergy can include:
            </BodyText>
            <ListContainer>
              <ListItem>
                <b>Genetic History:</b> Family history of asthma, eczema, hives,
                or allergies increases risk for food allergies.
              </ListItem>
              <ListItem>
                <b>Geography:</b> Risk for food allergies is higher in urban
                areas than rural areas.
              </ListItem>
              <ListItem>
                <b>Age:</b> Food allergies are more common in children. In some
                cases, many people with food allergies outgrow them depending on
                the type and severity of their allergy.
              </ListItem>
              <ListItem>
                <b>Ethnicity:</b> African American children are at increased
                risk of developing food allergies.
              </ListItem>
              <ListItem>
                <b>Asthma:</b> Asthma and food allergies often occur together.
              </ListItem>
              <ListItem>
                <b>Other Allergic Conditions:</b> People with one food allergy
                are more likely to develop another.
              </ListItem>
            </ListContainer>
          </Col>
        </Row>

        <SubHeader className="mt-5">Food Allergy Risks in Schools</SubHeader>

        <BodyText>
          Studies show that 1 out of 6 children with food allergies have had a
          reaction from accidentally eating food allergens while at school
          (Nowak-Wegrzyn et. al., 2001).
        </BodyText>

        <Row>
          <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
            <BodyText>
              Allergic reactions can occur in classrooms, buses, field trips,
              activities, P.E., and during recess. A majority of school-related
              anaphylactic events occur outside the cafeteria (Nowak-Wegrzyn et.
              al., 2001).
            </BodyText>
            <BodyText>
              Staff should always have quick access to EAIs in case there is a
              food allergy emergency.
            </BodyText>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            lg={{ span: 6, order: 2 }}
            className="d-flex flex-wrap align-items-center"
          >
            <ImageContainer>
              <Image fluid src={FoodAllergyReactionImg} />
            </ImageContainer>
          </Col>
        </Row>
      </TopPageContainer>

      <StyledJumbotron fluid>
        <Container className="text-center">
          <h1 className="d-inline">
            20% - 25% of school-related anaphylactic events occur in children
            without a known history of food allergy{" "}
          </h1>
          <h3 className="d-inline">(CDC, 2013).</h3>
        </Container>
      </StyledJumbotron>

      <PageContainer>
        <BodyText>
          Students who are at risk for an allergic reaction should keep an EAI
          with them at all times even during school activities. It is
          recommended that parents/legal guardians and students keep an
          emergency plan, like{" "}
          <StyledLink href={FareEmergencyActionPlan} target="_blank">
            FARE's Food Allergy & Anaphylaxis Emergency Care Plan
          </StyledLink>
          , which includes:
        </BodyText>

        <ListContainer>
          <ListItem>
            Personal information (name, D.O.B., allergies, weight)
          </ListItem>
          <ListItem>Outline of recommended treatment</ListItem>
          <ListItem>Emergency contacts</ListItem>
        </ListContainer>

        <BodyText>
          School staff should work collaboratively with parents and their
          doctors to ensure an appropriate care plan is established to address
          the child's allergies.
        </BodyText>

        <SubHeader>
          Best practices for reducing food allergen exposure in schools include:
        </SubHeader>

        <ListContainer>
          <ListItem>
            <b>Classrooms:</b> Establish "food rules" for the classroom and
            communicate with parents. Food rules could include things like
            making sure all ingredients are labeled on foods shared with the
            whole class.
          </ListItem>
          <ListItem>
            <b>Cafeterias:</b> Designate allergen-safe seating areas and talk to
            children about food sharing. This could be talking to kids about how
            their food could make someone else sick.
          </ListItem>
          <ListItem>
            <b>Transportation:</b> Encourage children not to eat or share their
            food on buses.
          </ListItem>
          <ListItem>
            <b>Field trips:</b> Invite parents of children with food allergies
            to be additional chaperones.
          </ListItem>
          <ListItem>
            <b>Recess:</b> Recommend children to wash their hands after recess
            before touching food.
          </ListItem>
        </ListContainer>
      </PageContainer>
    </>
  );
};

export default FoodAllergyRisks;
