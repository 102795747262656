import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { siteGray } from "../../providers/Theme";

export const StyledContainer = styled(Container)`
  background-color: ${siteGray};
`;

export const StyledRow = styled(Row)`
  background-color: ${siteGray};
`;

interface NavTextProps {
  color?: string;
  bold?: boolean;
  isLink?: boolean;
}

export const NavText = styled.div<NavTextProps>`
  font-size: 22px;

  ${props => props.color && `color: ${props.color};`};
  ${props => props.bold && `font-weight: bold;`};
  ${props => props.isLink && `cursor: pointer;`};
`;
